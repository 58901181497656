<template>
    <PageIntro>
        <div class="flex flex-col">
            <PageHeader>
                {{ label }}
            </PageHeader>
            <PageDescription v-if="!!CurrentUser">
                Welcome, {{ CurrentUser.username }}! <br />
                Select an account below by clicking on the arrows to the left of
                the account name.
            </PageDescription>
        </div>
    </PageIntro>
    <LoadingWrapper :isLoading="isLoading">
        <AgGridVue
            :style="{ height: '800px' }"
            class="ag-theme-alpine"
            domLayout="normal"
            :pagination="true"
            :paginationPageSize="15"
            :columnDefs="colDefs"
            :rowData="rowData"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            @column-resized="onColumnResized"
        >
        </AgGridVue>
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        onMounted,
        onUnmounted,
        ref,
        watchEffect,
    } from 'vue';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageHeader from '@components/PageHeader.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useSwitchAccount } from '@/features/switch-account/hooks/useSwitchAccount';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectAccountGrid',
        components: {
            LoadingWrapper,
            PageIntro,
            PageHeader,
            AgGridVue,
            PageDescription,
        },
        props: {
            label: {
                type: String,
                default: `Select Account`,
            },
        },
        emits: ['select', 'error'],
        setup(props, context) {
            // ==== COMPOSABLES ====
            const manager = useSwitchAccount({}, context);

            // ==== STATE ====
            const { constants, data, actions } = manager;
            const { CurrentUser, defaultColDef } = constants;
            const { rowData, colDefs } = data;
            const { syncLoadingOverlay, onGridReady, onColumnResized } =
                actions;

            /** @type {V.Ref<V.WatchStopHandle[]>} */
            const handles = ref(null);

            // ==== COMPUTED PROPERTIES ====
            const { isLoading } = data;

            // ==== LIFECYCLE ====
            /** onMounted - Register handlers and initiate fetch request. */
            onMounted(async () => {
                // Register watchers.
                handles.value = [watchEffect(syncLoadingOverlay)];

                // Refresh the accounts on initial page load.
                await actions.refreshAccounts(false);
            });

            /** onUnmounted - Unregister handlers. */
            onUnmounted(() => {
                // Stop all watchers.
                handles.value?.forEach((h) => h?.());
            });

            // ==== EXPOSE ====
            return {
                manager,
                // CONSTANTS
                CurrentUser,
                // STATE
                defaultColDef,
                rowData,
                colDefs,
                // CONDITIONALS,
                isLoading,
                // EVENTS
                onGridReady,
                onColumnResized,
            };
        },
    });
</script>

<style lang="scss"></style>
