// <!-- INTERFACES -->
import { PayloadResource } from '@/utils/resources';
import { PayloadAttributeFactory } from '@/utils/resources';
import { OrganizationModel } from '@/models/v2/organizations/OrganizationModel';

// <!-- RELATIONSHIPS -->
import { AccessPayload } from '@/payloads/v2/organizations/AccessPayload';
import { SubscriptionPayload } from '@/payloads/v2/subscriptions/SubscriptionPayload';

// <!-- ENUMS -->
import { ReminderFrequency, TemperatureScale } from '@/enums';
import { TimezonePayload } from '../timezones';
/**
 * Create a specialized resource instance.
 *
 * @extends {PayloadResource<PayloadData,ModelData>}
 * @implements {Resource.Payload<PayloadData,ModelData>}
 * @implements {PayloadData}
 */
export class OrganizationPayload extends PayloadResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Organization.Payload} PayloadData */
    /** @typedef {Organization.Model} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {OrganizationPayload}
     */
    static create(attributes) {
        return new OrganizationPayload(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} data
     * @returns {OrganizationPayload}
     */
    static fromModel(data) {
        const attributes = OrganizationPayload.attributesFromModel(data);
        return OrganizationPayload.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<PayloadData>} */
    static get defaults() {
        return {
            id: null,
            name: null,
            billing_email: null,
            contact_email: null,
            city: null,
            state: null,
            country: null,
            tree_labels: ['Site', 'Building', 'Floor', 'Room'],
            timezone: TimezonePayload.create({}),
            temperature_scale: TemperatureScale.Fahrenheit,
            reminder_frequency: ReminderFrequency.Never,
            last_reminder_at: null,
            created_at: null,
            updated_at: null,
            number_of_accounts: null,
            number_of_locations: null,
            number_of_users: null,
            number_of_subscriptions: null,
            number_of_current_subscriptions: null,
            access: AccessPayload.create({}),
            current_subscriptions: [],
        };
    }

    /**
     * Create resource attributes from the model data.
     *
     * @param {Partial<ModelData>} model
     * @returns {Partial<PayloadData>}
     */
    static attributesFromModel(model) {
        // Get transformer functions.
        const { coalesce, serialize } = this;

        // Transform attributes.
        const current_subscriptions = model.currentSubscriptions?.map(
            SubscriptionPayload.fromModel
        );

        // Return the created state.
        return {
            id: model.id,
            name: model.name,
            billing_email: model.email?.billing,
            contact_email: model.email?.contact,
            city: model.address?.city?.length > 0 ? model.address.city : null,
            state:
                model.address?.state?.length > 0 ? model.address.state : null,
            country:
                model.address?.country?.length > 0
                    ? model.address.country
                    : null,
            tree_labels:
                model.treeLabels?.length === 4 ? [...model.treeLabels] : null,
            timezone: TimezonePayload.fromValue(model.timezone),
            temperature_scale: model.temperatureScale,
            reminder_frequency: model.reminderFrequency,
            last_reminder_at: serialize.dateAsISOString(model.lastReminderAt),
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
            number_of_accounts: coalesce.count(model.numberOfAccounts),
            number_of_users: coalesce.count(model.numberOfUsers),
            number_of_subscriptions: coalesce.count(
                model.numberOfSubscriptions
            ),
            number_of_current_subscriptions: coalesce.count(
                model.numberOfCurrentSubscriptions
            ),
            number_of_locations: coalesce.count(model.numberOfLocations),
            access: AccessPayload.fromModel(model.access),
            current_subscriptions,
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<PayloadData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            PayloadAttributeFactory.create(
                OrganizationPayload.attributesFromModel,
                OrganizationPayload.defaults
            )
        );

        // Hydrate sub-resource.
        if (
            this.exists('timezone') &&
            !(this.timezone instanceof TimezonePayload)
        ) {
            this.timezone = TimezonePayload.create(this.timezone);
        }

        // Hydrate sub-resource.
        if (this.exists('access') && !(this.access instanceof AccessPayload)) {
            this.access = AccessPayload.create(this.access);
        }
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a resource. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy of this instance as a resource. */
    toModel() {
        return OrganizationModel.fromPayload(this);
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (OrganizationPayload.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get name() {
        return this.get('name');
    }

    set name(value) {
        this.set('name', value);
    }

    get billing_email() {
        return this.get('billing_email');
    }

    set billing_email(value) {
        this.set('billing_email', value);
    }

    get contact_email() {
        return this.get('contact_email');
    }

    set contact_email(value) {
        this.set('contact_email', value);
    }

    get city() {
        return this.get('city');
    }

    set city(value) {
        this.set('city', value);
    }

    get state() {
        return this.get('state');
    }

    set state(value) {
        this.set('state', value);
    }

    get country() {
        return this.get('country');
    }

    set country(value) {
        this.set('country', value);
    }

    get tree_labels() {
        return this.get('tree_labels');
    }

    set tree_labels(value) {
        this.set('tree_labels', value);
    }

    get timezone() {
        return this.get('timezone');
    }

    set timezone(value) {
        this.set('timezone', value);
    }

    get temperature_scale() {
        return this.get('temperature_scale');
    }

    set temperature_scale(value) {
        this.set('temperature_scale', value);
    }

    get reminder_frequency() {
        return this.get('reminder_frequency');
    }

    set reminder_frequency(value) {
        this.set('reminder_frequency', value);
    }

    get last_reminder_at() {
        return this.get('last_reminder_at');
    }

    set last_reminder_at(value) {
        this.set('last_reminder_at', value);
    }

    get created_at() {
        return this.get('created_at');
    }

    set created_at(value) {
        this.set('created_at', value);
    }

    get updated_at() {
        return this.get('updated_at');
    }

    set updated_at(value) {
        this.set('updated_at', value);
    }

    get number_of_accounts() {
        return this.get('number_of_accounts');
    }

    set number_of_accounts(value) {
        this.set('number_of_accounts', value);
    }

    get number_of_users() {
        return this.get('number_of_users');
    }

    set number_of_users(value) {
        this.set('number_of_users', value);
    }

    get number_of_subscriptions() {
        return this.get('number_of_subscriptions');
    }

    set number_of_subscriptions(value) {
        this.set('number_of_subscriptions', value);
    }

    get number_of_current_subscriptions() {
        return this.get('number_of_current_subscriptions');
    }

    set number_of_current_subscriptions(value) {
        this.set('number_of_current_subscriptions', value);
    }

    get number_of_locations() {
        return this.get('number_of_locations');
    }

    set number_of_locations(value) {
        this.set('number_of_locations', value);
    }

    get access() {
        return this.get('access');
    }

    set access(value) {
        this.set('access', value);
    }

    get current_subscriptions() {
        return this.get('current_subscriptions');
    }

    set current_subscriptions(value) {
        this.set('current_subscriptions', value);
    }
}
