// <!-- DEFINE -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
const children = {
    organizations: {
        path: 'organizations',
        name: 'Admin Organizations',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "admin" */ '@/features/organization-manager/views/Organizations.vue'
            ),
        meta: { namespace: 'administrator' },
        alias: ['/organizations'],
    },
    subscription: {
        path: 'subscription-information',
        name: 'Subcription Information',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "admin" */ '@/features/subscription-manager/views/SubscriptionInformation.vue'
            ),
        meta: { namespace: 'administrator' },
        alias: ['/subscription-information'],
    },
    accounts: {
        path: 'accounts',
        name: 'Admin Accounts',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "admin" */ '@/features/account-manager/views/Accounts.vue'
            ),
        meta: { namespace: 'administrator' },
        alias: ['/accounts'],
    },
    users: {
        path: 'users',
        name: 'Admin Users',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "admin" */ '@/features/user-manager/views/Users.vue'
            ),
        meta: { namespace: 'administrator' },
        alias: ['/users'],
    },
    'nara-standards': {
        path: 'nara-standards',
        name: 'Admin NARA Standards',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "admin" */ '@/features/nara-standards/views/NARAStandards.vue'
            ),
        meta: { namespace: 'administrator' },
        alias: ['/nara-standards', '/standards'],
    },
};

// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const admin = {
    index: {
        path: '/admin',
        name: 'Admin Dashboard',
        redirect: '/admin/organizations',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "admin" */ '@/features/admin/views/index.vue'
            ),
        meta: { namespace: 'administrator', requiresAdmin: true },
        children: [
            children.subscription,
            children.organizations,
            children.accounts,
            children.users,
            children['nara-standards'],
        ],
    },
};

// <!-- DEFAULT -->
export default admin;
