// <!-- INTERFACES -->
import { ModelResource } from '@/utils/resources';
import { ModelAttributeFactory } from '@/utils/resources';
import { AccessPayload } from '@/payloads/v2/organizations/AccessPayload';

// <!-- ENUMS -->
import { UserAccountRole } from '@/enums';

/**
 * Create a specialized resource instance.
 *
 * @extends {ModelResource<PayloadData,ModelData>}
 * @implements {Resource.Model<PayloadData,ModelData>}
 * @implements {ModelData}
 */
export class AccessModel extends ModelResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Organization.Payload.OnUser} PayloadData */
    /** @typedef {Organization.Model.OnUser} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} attributes
     * @returns {AccessModel}
     */
    static create(attributes) {
        return new AccessModel(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} data
     * @returns {AccessModel}
     */
    static fromPayload(data) {
        const attributes = AccessModel.attributesFromPayload(data);
        return AccessModel.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<ModelData>} */
    static get defaults() {
        return {
            userId: null,
            organizationId: null,
            createdAt: null,
            updatedAt: null,
            userRole: UserAccountRole.Guest,
        };
    }

    /**
     * Create resource attributes from the payload data.
     *
     * @param {Partial<PayloadData>} payload
     * @returns {Partial<ModelData>}
     */
    static attributesFromPayload(payload = {}) {
        // Get transformer functions.
        const { parse } = this;

        // Return the created state.
        return {
            userId: payload.user_id,
            organizationId: payload.organization_id,
            createdAt: parse.dateString(payload.created_at),
            updatedAt: parse.dateString(payload.updated_at),
            userRole: payload.user_role,
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<ModelData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            ModelAttributeFactory.create(
                AccessModel.attributesFromPayload,
                AccessModel.defaults
            )
        );
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Organization\\Access\\Model';
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a resource. */
    toPayload() {
        return AccessPayload.fromModel(this);
    }

    /** Get shallow copy of this instance as a resource. */
    toModel() {
        return this.clone();
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (AccessModel.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get userId() {
        return this.get('userId');
    }

    set userId(value) {
        this.set('userId', value);
    }

    get organizationId() {
        return this.get('organizationId');
    }

    set organizationId(value) {
        this.set('organizationId', value);
    }

    get createdAt() {
        return this.get('createdAt');
    }

    set createdAt(value) {
        this.set('createdAt', value);
    }

    get updatedAt() {
        return this.get('updatedAt');
    }

    set updatedAt(value) {
        this.set('updatedAt', value);
    }

    get userRole() {
        return this.get('userRole');
    }

    set userRole(value) {
        this.set('userRole', value);
    }
}

// <!-- EXPORTS -->
export default AccessModel;
