// <!-- API -->
import { computed } from 'vue';

// <!-- UTILITIES -->
import pick from 'just-pick';
import omit from 'just-omit';
import isNil from 'lodash-es/isNil';

// <!-- STATE -->
import { AccountState } from '@/store/types/accounts/state/AccountState';
import { UserState } from '@/store/types/users/state/UserState';
import { AnalysisState } from '@/store/types/analysis/state/AnalysisState';
import { UploadState } from '@/store/types/uploader/state/UploadState';

// <!-- TYPES -->
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('vuex').Commit} Commit */
/** @typedef {import('vuex').Dispatch} Dispatch */
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
/** @typedef {import('@/models/v1/weather/WeatherStation').WeatherStationResource} WeatherStationResource */
import {
    CacheState,
    LocationHierarchyIndexState,
    LocationIndexState,
    MappingProfileIndexState,
    NoteIndexState,
    UserIndexState,
    AccountIndexState,
    OrganizationIndexState,
    NARAStandardIndexState,
    WeatherStationIndexState,
} from '@/store/types/cache/state';
import { Emoji } from '@/utils/emoji';
import {
    UploadData,
    UploadErrors,
    UploadWorkflow,
} from '@/store/types/uploader/state';

/**
 * @class
 * Root state used for the ECNB Vuex store.
 */
export class ECNBState {
    constructor() {
        this.accounts = new AccountState();
        this.analysis = new AnalysisState();
        this.cache = Object.assign({}, new CacheState(), {
            /** @type {LocationIndexState} */
            locations: new LocationIndexState(),
            /** @type {WeatherStationIndexState} */
            stations: new WeatherStationIndexState(),
            /** @type {LocationHierarchyIndexState} */
            hierarchies: new LocationHierarchyIndexState(),
            /** @type {MappingProfileIndexState} */
            profiles: new MappingProfileIndexState(),
            /** @type {NoteIndexState} */
            notes: new NoteIndexState(),
            /** @type {UserIndexState} */
            users: new UserIndexState(),
            /** @type {OrganizationIndexState} */
            organizations: new OrganizationIndexState(),
            /** @type {AccountIndexState} */
            accounts: new AccountIndexState(),
            /** @type {NARAStandardIndexState} */
            standards: new NARAStandardIndexState(),
        });
        this.uploader = Object.assign({}, new UploadState(), {
            data: new UploadData(),
            errors: new UploadErrors(),
            workflow: new UploadWorkflow(),
        });
        this.users = new UserState();
    }
}

/**
 * @class
 * ECNBStore instance for accessing the Vuex state with
 * a consistent API.
 */
export class ECNBStore {
    /**
     * Collection of resolvers that help get the appropriately scoped type, getter, mutation, or action.
     */
    static get resolve() {
        // Create instance of a resolver, so we can reuse it
        // while it exists.
        const $resolve = {
            /**
             * Get the state from a store instance.
             * @param {Pick<Store<ECNBState>, 'state'>} store Vuex store instance.
             */
            state: (store) => {
                return {
                    /**
                     * Get specific namespace state from the store.
                     * @param {keyof store['state']} namespace
                     */
                    get: (namespace) => store.state[namespace],
                    /**
                     * Get specific namespace state from the store.
                     * @param {keyof store['state']['cache']} key
                     */
                    cache: (key) => store.state.cache[key],
                    /**
                     * Create computed properties for accessing the {@link ECNBStore} state. Creates {@link $root} state property on access.
                     */
                    computed: () => {
                        const $root = computed(() => store.state);
                        // Return computed properties.
                        return {
                            /**
                             * Computed property yielding the root store state.
                             */
                            root: $root,
                            /**
                             * Accounts module computed properties. Module property created on access.
                             */
                            get accounts() {
                                const $accounts = computed(
                                    () => store.state.accounts
                                );
                                return {
                                    /**
                                     * Computed property yielding the accounts module state.
                                     */
                                    module: $accounts,
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get organization() {
                                        return computed(
                                            () =>
                                                store.state.accounts
                                                    .organization
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get account() {
                                        return computed(
                                            () => store.state.accounts.account
                                        );
                                    },
                                };
                            },
                            /**
                             * Users module computed properties. Module property created on access.
                             */
                            get users() {
                                const $users = computed(
                                    () => store.state.users
                                );
                                return {
                                    /**
                                     * Computed property yielding the accounts module state.
                                     */
                                    module: $users,
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get user() {
                                        return computed(
                                            () => store.state.users.me
                                        );
                                    },
                                };
                            },
                            /**
                             * Analysis module computed properties. Module property created on access.
                             */
                            get analysis() {
                                const $analyis = computed(
                                    () => store.state.analysis
                                );
                                return {
                                    /**
                                     * Computed property yielding the module state.
                                     */
                                    module: $analyis,
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get dates() {
                                        return computed(
                                            () =>
                                                store.state.analysis.filters
                                                    .dates
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get startDate() {
                                        return computed(
                                            () =>
                                                store.state.analysis.filters
                                                    .dates.start
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get endDate() {
                                        return computed(
                                            () =>
                                                store.state.analysis.filters
                                                    .dates.end
                                        );
                                    },
                                };
                            },
                            /**
                             * Cache module computed properties. Module property created on access.
                             */
                            get cache() {
                                const $cache = computed(
                                    () => store.state.cache
                                );
                                return {
                                    /**
                                     * Computed property yielding the module state.
                                     */
                                    module: $cache,
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get locations() {
                                        return computed(
                                            () => store.state.cache.locations
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get notes() {
                                        return computed(
                                            () => store.state.cache.notes
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get profiles() {
                                        return computed(
                                            () => store.state.cache.profiles
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get stations() {
                                        return computed(
                                            () => store.state.cache.stations
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get hierarchies() {
                                        return computed(
                                            () => store.state.cache.hierarchies
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get users() {
                                        return computed(
                                            () => store.state.cache.users
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get organizations() {
                                        return computed(
                                            () =>
                                                store.state.cache.organizations
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get accounts() {
                                        return computed(
                                            () => store.state.cache.accounts
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get standards() {
                                        return computed(
                                            () => store.state.cache.standards
                                        );
                                    },
                                };
                            },
                            /**
                             * Uploader module computed properties. Module property created on access.
                             */
                            get uploader() {
                                const $uploader = computed(
                                    () => store.state.uploader
                                );
                                return {
                                    /**
                                     * Computed property yielding the module state.
                                     */
                                    module: $uploader,
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get config() {
                                        return computed(
                                            () => store.state.uploader.config
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get data() {
                                        return computed(
                                            () => store.state.uploader.data
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get workflow() {
                                        return computed(
                                            () => store.state.uploader.workflow
                                        );
                                    },
                                    /**
                                     * Computed property yielding a property of the module state. New property created on access.
                                     */
                                    get errors() {
                                        return computed(
                                            () => store.state.uploader.errors
                                        );
                                    },
                                };
                            },
                        };
                    },
                    /**
                     * Get live getters that provide uncached access to store state.
                     */
                    live: () => {
                        const $root = () => store.state;
                        // Return live properties.
                        return {
                            /**
                             * Accessor yielding the root store state.
                             */
                            root: $root,
                            /**
                             * Accounts module accessor.
                             */
                            get accounts() {
                                const $accounts = () => $root().accounts;
                                return {
                                    /**
                                     * Accessor yielding the accounts module state.
                                     */
                                    module: $accounts,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    organization: () =>
                                        $accounts().organization,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    account: () => $accounts().account,
                                };
                            },
                            /**
                             * Users module computed properties. Module property created on access.
                             */
                            get users() {
                                const $users = () => $root().users;
                                return {
                                    /**
                                     * Accessor yielding the accounts module state.
                                     */
                                    module: $users,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    user: () => $users().me,
                                };
                            },
                            /**
                             * Analysis module computed properties. Module property created on access.
                             */
                            get analysis() {
                                const $analysis = () => $root().analysis;
                                return {
                                    /**
                                     * Accessor yielding the accounts module state.
                                     */
                                    module: $analysis,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    dates: () => $analysis().filters.dates,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    startDate: () =>
                                        $analysis().filters.dates.start,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    endDate: () =>
                                        $analysis().filters.dates.end,
                                };
                            },
                            /**
                             * Cache module computed properties. Module property created on access.
                             */
                            get cache() {
                                const $cache = () => $root().cache;
                                return {
                                    /**
                                     * Accessor yielding the accounts module state.
                                     */
                                    module: $cache,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    locations: () => $cache().locations,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    notes: () => $cache().notes,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    profiles: () => $cache().profiles,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    stations: () => $cache().stations,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    hierarchies: () => $cache().hierarchies,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    standards: () => $cache().standards,
                                };
                            },
                            /**
                             * Uploader module computed properties. Module property created on access.
                             */
                            get uploader() {
                                const $uploader = () => $root().uploader;
                                return {
                                    /**
                                     * Accessor yielding the accounts module state.
                                     */
                                    module: $uploader,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    config: () => $uploader().config,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    workflow: () => $uploader().workflow,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    data: () => $uploader().data,
                                    /**
                                     * Accessor yielding a property of the module state. New property created on access.
                                     */
                                    errors: () => $uploader().errors,
                                };
                            },
                        };
                    },
                };
            },
            /**
             * Resolve label of specified type.
             * @param {String} type
             */
            label: (type) => ({
                /**
                 * Create label with type and method. Assume type is resolved.
                 * @param {String} method
                 */
                forMethod: (method) => `[${method}::${type}]`,
                /**
                 * Create label for getter. Assume type is resolved.
                 */
                getter: () => $resolve.label(type).forMethod('getter'),
                /**
                 * Create label for mutation. Assume type is resolved.
                 */
                mutation: () => $resolve.label(type).forMethod('mutation'),
                /**
                 * Create label for action. Assume type is resolved.
                 */
                action: () => $resolve.label(type).forMethod('action'),
                /**
                 * Create label for global type.
                 */
                asGlobal: () => {
                    const $type = $resolve.type(type).asGlobal();
                    return omit(
                        $resolve.label($type),
                        'asGlobal',
                        'asNamespaced'
                    );
                },
                /**
                 * Create label for namespaced type.
                 * @param {String} namespace
                 */
                asNamespaced: (namespace) => {
                    const $type = $resolve.type(type).asNamespaced(namespace);
                    return omit($resolve.label($type), 'asGlobal');
                },
            }),
            /**
             * Resolve the provided getter, mutation, or action type.
             * @param {String} type Type to resolve, either locally or with a namespace.
             */
            type: (type) => ({
                /**
                 * Resolve type using the provided configuration object.
                 * @param {Object} [config]
                 * @param {String} [config.namespace] Namespace to use, when `isNamespaced` is true.
                 * @param {Boolean} [config.isNamespaced] Should the type be resolved as a namespaced type or as a global root type?
                 */
                withConfig: (config = {}) => {
                    const { namespace = '', isNamespaced = false } = config;
                    const withNamespace =
                        !isNil(namespace) && isNamespaced === true;
                    const useNamespace = () => `${namespace}/${type}`;
                    const useGlobal = () => `${type}`;
                    return withNamespace ? useNamespace() : useGlobal();
                },
                /**
                 * Resolve type using the exact, root namespaced type.
                 */
                asGlobal: () =>
                    $resolve.type(type).withConfig({ isNamespaced: false }),
                /**
                 * Resolve type using the namespace.
                 * @param  {String} namespace
                 */
                asNamespaced: (namespace) =>
                    $resolve
                        .type(type)
                        .withConfig({ namespace, isNamespaced: true }),
            }),
            /**
             * Resolve the provided getter of the specified type.
             * @param {String} type Type to resolve, either locally or with a namespace.
             */
            getter: (type) => ({
                /**
                 * @template {any} [P=any]
                 * @template {any} [V=any]
                 * Resolve getter with passed type. Assumes type has been resolved.
                 * @param {Record<String, any>} getters
                 * @param {Object} [config]
                 * @param {Boolean} [config.isFunction] Evaluate getter type as function.
                 * @param {P} [config.payload] Optional parameters to pass as payload to the getter function.
                 * @param {V} [config.defaultValue] Default value to return if the result is undefined.
                 * @returns {V}
                 */
                withConfig: (getters, config = {}) => {
                    const {
                        isFunction = false,
                        payload = undefined,
                        defaultValue = undefined,
                    } = config;

                    // Negate isFunction.
                    const isProperty = isFunction !== true;

                    /** @type {() => V} */
                    const asProperty = () => getters?.[type] ?? defaultValue;

                    /** @type {() => V} */
                    const asFunction = () =>
                        getters?.[type](payload) ?? defaultValue;

                    // Get label.
                    const label = $resolve.label(type).getter();

                    // Evaluate getter.
                    try {
                        console.groupCollapsed(label);
                        console.time(label);
                        console.table(
                            isProperty
                                ? { isFunction }
                                : { isFunction, payload }
                        );
                        const result = isProperty ? asProperty() : asFunction();
                        console.log(
                            `${label} - ${Emoji.cross} Evaluated getter successfully!`
                        );
                        console.table({ [type]: result });
                        return result;
                    } catch (error) {
                        console.log(
                            `${label} - ${Emoji.cross} Failed to evaluate getter!`
                        );
                        console.table({ error });
                    } finally {
                        console.timeEnd(label);
                        console.groupEnd();
                    }
                },
                /**
                 * @template {any} [V=any]
                 * Resolve getter with passed type as a getter property. Assumes type has been resolved.
                 * @param {Record<String, any>} getters
                 * @param {V} [defaultValue] Optional default value.
                 * @returns {V}
                 */
                asProperty: (getters, defaultValue) =>
                    $resolve.getter(type).withConfig(getters, {
                        isFunction: false,
                        defaultValue,
                    }),
                /**
                 * Resolve getter with passed type as a function with parameters. Assumes type has been resolved.
                 * @template {any} [P=any]
                 * @template {any} [V=any]
                 * @param {Record<String, any>} getters
                 * @param {P} [payload] Optional parameters.
                 * @param {V} [defaultValue] Optional default value.
                 * @returns {V}
                 */
                asFunction: (getters, payload, defaultValue) =>
                    $resolve.getter(type).withConfig(getters, {
                        isFunction: true,
                        payload,
                        defaultValue,
                    }),
                /**
                 * @template {any} [P=any]
                 * Resolve getter with passed type as a function with parameters. Assumes type has been resolved.
                 * @param {P} [payload] Optional parameters.
                 */
                withPayload: (payload) => ({
                    /**
                     * @template {any} [V=any]
                     * Resolve getter with passed type as a function with parameters. Assumes type has been resolved.
                     * @param {Record<String, any>} getters
                     * @param {V} [defaultValue] Optional default value.
                     * @returns {V}
                     */
                    using: (getters, defaultValue) =>
                        $resolve
                            .getter(type)
                            .asFunction(getters, payload, defaultValue),
                }),
                /**
                 * Resolve getter type using the exact, root namespaced type.
                 */
                asGlobal: () => {
                    const $type = $resolve.type(type).asGlobal();
                    return omit(
                        $resolve.getter($type),
                        'asGlobal',
                        'asNamespaced'
                    );
                },
                /**
                 * Resolve getter type using the namespace.
                 * @param  {String} namespace
                 */
                asNamespaced: (namespace) => {
                    const $type = $resolve.type(type).asNamespaced(namespace);
                    return omit($resolve.getter($type), 'asGlobal');
                },
            }),
            /**
             * Resolve the provided getter of the specified type.
             * @param {String} type Type to resolve, either locally or with a namespace.
             */
            mutation: (type) => ({
                /**
                 * @template {any} [P=any]
                 * Resolve mutation with passed type. Assumes type has been resolved.
                 * @param {Commit} commit
                 * @param {Object} [config]
                 * @param {P} [config.payload] Optional parameters to pass as payload to the mutation function.
                 */
                withConfig: (commit, config = {}) => {
                    const { payload = undefined } = config;
                    const applyMutation = () => commit(type, payload);

                    // Get label.
                    const label = $resolve.label(type).mutation();

                    // Evaluate mutation.
                    try {
                        console.groupCollapsed(label);
                        console.time(label);
                        console.table({ payload });
                        applyMutation();
                        console.log(
                            `${label} - ${Emoji.checkmark} Applied mutation!`
                        );
                        return;
                    } catch (error) {
                        console.log(
                            `${label} - ${Emoji.cross} Failure to apply mutation!`
                        );
                        console.table({ error });
                    } finally {
                        console.timeEnd(label);
                        console.groupEnd();
                    }
                },
                /**
                 * @template {any} [P=any]
                 * Resolve mutation with passed type as a function with parameters. Assumes type has been resolved.
                 * @param {P} [payload] Optional parameters. Default to undefined.
                 */
                withPayload: (payload) => ({
                    /**
                     * Resolve mutation with passed type as a function with parameters. Assumes type has been resolved.
                     * @param {Commit} commit
                     * @returns {void}
                     */
                    using: (commit) =>
                        $resolve.mutation(type).withConfig(commit, { payload }),
                }),
                /**
                 * Resolve mutation type using the exact, root namespaced type.
                 */
                asGlobal: () => {
                    const $type = $resolve.type(type).asGlobal();
                    return omit(
                        $resolve.mutation($type),
                        'asGlobal',
                        'asNamespaced'
                    );
                },
                /**
                 * Resolve getter type using the namespace.
                 * @param  {String} namespace
                 */
                asNamespaced: (namespace) => {
                    const $type = $resolve.type(type).asNamespaced(namespace);
                    return omit($resolve.mutation($type), 'asGlobal');
                },
            }),
            /**
             * Resolve the provided getter of the specified type.
             * @param {String} type Type to resolve, either locally or with a namespace.
             */
            action: (type) => ({
                /**
                 * @template {any} [P=any]
                 * Resolve action with passed type. Assumes type has been resolved.
                 * @param {Dispatch} dispatch
                 * @param {Object} [config]
                 * @param {P} [config.payload] Optional parameters to pass as payload to the mutation function.
                 */
                withConfig: (dispatch, config = {}) => {
                    const { payload = undefined } = config;
                    const invokeAction = () => dispatch(type, payload);

                    // Get label.
                    const label = $resolve.label(type).action();

                    // Evaluate mutation.
                    try {
                        console.groupCollapsed(label);
                        console.time(label);
                        console.table({ payload });
                        const promise = invokeAction();
                        console.log(
                            `${label} - ${Emoji.checkmark} Invoked action successfully!`
                        );
                        return promise;
                    } catch (error) {
                        console.log(
                            `${label} - ${Emoji.cross} Failed to invoke action!`
                        );
                        console.table({ error });
                    } finally {
                        console.timeEnd(label);
                        console.groupEnd();
                    }
                },
                /**
                 * @template {any} [P=any]
                 * Resolve getter with passed type as a function with parameters. Assumes type has been resolved.
                 * @param {P} [payload] Optional parameters.
                 */
                withPayload: (payload) => ({
                    /**
                     * Resolve action with passed type as a function with parameters. Assumes type has been resolved.
                     * @param {Dispatch} dispatch
                     */
                    using: (dispatch) =>
                        $resolve.action(type).withConfig(dispatch, { payload }),
                }),
                /**
                 * Resolve mutation type using the exact, root namespaced type.
                 */
                asGlobal: () => {
                    const $type = $resolve.type(type).asGlobal();
                    return omit(
                        $resolve.action($type),
                        'asGlobal',
                        'asNamespaced'
                    );
                },
                /**
                 * Resolve getter type using the namespace.
                 * @param  {String} namespace
                 */
                asNamespaced: (namespace) => {
                    const $type = $resolve.type(type).asNamespaced(namespace);
                    return omit($resolve.action($type), 'asGlobal');
                },
            }),
        };
        // Return resolver.
        return $resolve;
    }

    /**
     * Interface for the Vuex store with useful top-level helpers.
     * @param {Store<ECNBState>} store Store instance.
     */
    constructor(store) {
        /**
         * Store context this is wrapping.
         * @type {Store<ECNBState>}
         */
        this.context = store;

        /**
         * Specially picked public {@link Store} functions.
         * @type {Pick<store, 'state' | 'commit' | 'dispatch' | 'getters' | 'subscribe' | 'subscribeAction' | 'watch'>}
         */
        const _core = pick(
            store,
            'state',
            'commit',
            'dispatch',
            'getters',
            'subscribe',
            'subscribeAction',
            'watch'
        );

        /** Computed property creator. */
        const $computed = ECNBStore.resolve.state(store).computed();

        /** Live property creator. */
        const $live = ECNBStore.resolve.state(store).live();

        /**
         * Store API extensions.
         */
        const _extensions = {
            /**
             * Create computed or live properties using these helpers.
             */
            create: {
                computed: $computed,
                live: $live,
            },
        };

        /**
         * Interface to allowed public {@link Store} functions and useful getters.
         * @type {{ store: Store<ECNBState> } & Pick<store, 'state' | 'commit' | 'dispatch' | 'getters' | 'subscribe' | 'subscribeAction' | 'watch'> & _extensions}
         */
        this.api = {
            store,
            ..._core,
            ..._extensions,
        };
    }
}

// DEFAULT
export default ECNBStore;
