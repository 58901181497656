// <!-- UTILITIES -->
import { add, differenceInSeconds, formatISODuration, isValid } from 'date-fns';

// <!-- PATTERNS -->
const DURATION_REGEX =
    /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;

/**
 * Singularize a plural unit.
 *
 * @param {string} pluralKey
 * @returns {string}
 */
const singularize = (pluralKey) => {
    return {
        years: 'year',
        months: 'month',
        weeks: 'week',
        days: 'day',
        hours: 'hour',
        minutes: 'minute',
        seconds: 'second',
    }[pluralKey];
};

// <!-- UTILITY -->
export class DurationISO {
    /**
     * Format a duration object into an ISO-8601 duration string.
     *
     * @param {Duration | Record<string,never>} duration
     * @returns {string}
     */
    static format(duration = {}) {
        return formatISODuration(duration);
    }

    /**
     * Parse an ISO-8601 duration string into a duration object.
     * @param {string} iso
     * @returns {Duration}
     */
    static parse(iso = 'PT') {
        const [
            ,
            // Skip the first element.
            years,
            months,
            weeks,
            days,
            hours,
            minutes,
            seconds,
        ] = iso.match(DURATION_REGEX);

        return {
            years: Number.parseInt(years ?? '0'),
            months: Number.parseInt(months ?? '0'),
            weeks: Number.parseInt(weeks ?? '0'),
            days: Number.parseInt(days ?? '0'),
            hours: Number.parseInt(hours ?? '0'),
            minutes: Number.parseInt(minutes ?? '0'),
            seconds: Number.parseInt(seconds ?? '0'),
        };
    }

    /**
     * Create a unique duration label.
     *
     * @param {Duration | string} durationOrString
     */
    static label(durationOrString) {
        // Normalize to a duration object.
        const duration =
            typeof durationOrString === 'string'
                ? DurationISO.parse(durationOrString)
                : durationOrString;

        // For all properties, create the appropriate label segments.
        const segments = [];
        for (const key in duration) {
            const value = duration[key] ?? 0;
            if (value === 0) {
                continue;
            }
            if (value === 1) {
                segments.push(`${value} ${singularize(key)}`);
                continue;
            }
            if (value > 1) {
                segments.push(`${value} ${key}`);
                continue;
            }
        }

        // Join the segments.
        return segments.join(',');
    }

    /**
     * Get the number of seconds in the duration.
     *
     * @param {Duration | string} durationOrString
     */
    static magnitude(durationOrString) {
        // Normalize to a duration object.
        const duration =
            typeof durationOrString === 'string'
                ? DurationISO.parse(durationOrString)
                : durationOrString;

        // Get base date.
        const start = new Date(0);

        // Add duration to the date.
        const end = add(start, duration);

        // Get the total magnitude in seconds.
        return isValid(start) && isValid(end)
            ? differenceInSeconds(end, start)
            : 0;
    }
}

// <!-- DEFAULT EXPORT -->
export default DurationISO;
