// <!-- API -->
import { computed } from 'vue';

// <!-- UTILITIES -->
import { LocalStorage } from '@/features/auth/utils/LocalStorage';

// <!-- TYPES -->
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('@/store/types/ECNBStore').ECNBState} ECNBState */

// <!-- COMPOSABLES -->
import { useNARAFeature } from '@/utils/features';

// Determine if NARA is enabled.
const { isNARADisabled, isNARAEnabled } = useNARAFeature();

/**
 * Checks if the bearer authentication token has been stored on successful login.
 * @returns {Boolean}
 */
export const isUserAuthenticated = () => {
    if (LocalStorage.has('auth_token')) {
        const isValidToken = !!LocalStorage.get('auth_token', null);
        return isValidToken;
    }
    // Not authenticated.
    return false;
};

/**
 * Checks if the user is authorized for their selected account.
 * @param {Store<ECNBState>} store
 * @returns {Boolean}
 */
export const isUserAuthorized = (store) => {
    const hasUser =
        isUserAuthenticated() && store.state.users?.hasUser === true;
    const hasAccount = store.state.accounts?.hasAccount === true;
    const hasOrganization = store.state.accounts?.hasOrganization === true;
    return hasUser && hasAccount && hasOrganization;
};

/**
 * Set of routes that should redirect when the user is logged in.
 * @type {Set<String | Symbol>}
 */
const RequireUnauthenticated = new Set(
    /** @type {const} */ ([
        'Login',
        'Sign Up',
        'Forgot Password',
        'Reset Password',
    ])
);

/**
 * Set of routes that should redirect when the user is not logged in OR when the user does not have a selected account.
 * @type {Set<String | Symbol>}
 */
const RequireAccount = new Set(
    /** @type {const}*/ ([
        'Home',
        'Analysis',
        'Statistics',
        'Compare Metrics',
        'NARA Standards',
        'Reports',
        'Admin Dashboard',
        'Admin Accounts',
        'Admin Users',
        'Admin NARA Standards',
        'Data Manager',
        'Locations',
        'Location Detail',
        'Weather Stations',
        'Note Manager',
        'Notes',
        'Account Information',
        'User Manager',
        'CSV Uploader',
        'Upload',
    ])
);

/**
 * Set of routes that should redirect when the user is not logged in.
 * @type {Set<String | Symbol>}
 */
const RequireAuthenticated = new Set(
    //TODO: Require for Select Organization page
    /** @type {const} */ (['Select Organization', 'Select Account'])
);

/**
 * Set of routes that should redirect when the site is NARA enabled.
 * @type {Set<String | Symbol>}
 */
const RequireNARA = new Set(
    /** @type {const} */ (['NARA Standards', 'Admin NARA Standards'])
);

/**
 * Set of routes that should redirect when the site IS NARA enabled.
 * @type {Set<String | Symbol>}
 */
const RequireCommercial = new Set(
    /** @type {const} */ (['Sign Up', 'Subscription Information'])
);

/**
 * Set of routes that should redirect when a user is NOT an IPI_Admin.
 * @type {Set<String | Symbol>}
 */
const RequireIPIAdmin = new Set(/** @type {const} */ (['Admin Accounts']));

/**
 * Determine if the route has a valid name.
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 * @returns {Boolean}
 */
const isRouteValid = (route) => !!route && !!route?.name && route?.name !== '';

/**
 * Is the specified route one that has no authorization pre-requisites?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isPublic = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresAuthentication = RequireAuthenticated.has(name);
        const requiresAccount = RequireAccount.has(name);
        return !requiresAuthentication && !requiresAccount;
    }
    // Invalid routes are falsy.
    return false;
};

/**
 * Is the provided route one that requires authentication?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isAuthenticationRequired = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresAuthentication = RequireAuthenticated.has(name);
        const requiresAccount = RequireAccount.has(name);
        return requiresAccount || requiresAuthentication;
    }
    // Invalid routes are falsy.
    return false;
};

/**
 * Is the provided route one that requires authentication and an authorized account?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isAccountRequired = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresAccount = RequireAccount.has(name);
        return requiresAccount;
    }
    // Invalid routes are falsy.
    return false;
};

/**
 * Is the provided route one that requires the user to be unauthenticated?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isAuthenticationSkipped = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresUnauthenticated = RequireUnauthenticated.has(name);
        return requiresUnauthenticated;
    }
    // Invalid routes are falsy.
    return false;
};

export const isNARARequired = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresNARA = RequireNARA.has(name);
        return requiresNARA;
    }
};

export const isCommercialRequired = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresCommercial = RequireCommercial.has(name);
        return requiresCommercial;
    }
};

export const isIPIAdminRequired = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresIPIAdmin = RequireIPIAdmin.has(name);
        return requiresIPIAdmin;
    }
};

/**
 * Register the NARA navigation guard.
 * @param {Router.Router} router
 * @param {Store<ECNBState>} store
 */
export const useNARARedirects = (router, store) => {
    // Register the navigation guards.
    router?.beforeEach(async (to, from, next) => {
        if (isNARARequired(to) && isNARADisabled.value) {
            // If NARA is not enabled...
            // ...redirect to Home page.
            next({ name: 'Home' });
            return;
        }
        if (isCommercialRequired(to) && isNARAEnabled.value) {
            // If NARA is enabled...
            // ...redirect to Login page.
            next({ name: 'Login' });
            return;
        }

        // If no guard condition is met, allow user to continue.
        next();
        return;
    });
};

/**
 * Register the navigation guard.
 * @param {Router.Router} router
 * @param {Store<ECNBState>} store
 */
export const useIPIAdminRedirects = (router, store) => {
    // Register the navigation guard.
    router?.beforeEach(async (to, from, next) => {
        //TODO: Update with future IPI_Admin role
        //const user = computed(() => store.state.users.me);
        //const userRole = computed(() => user.value.role);
        if (false) {
            if (!isIPIAdminRequired(to)) {
                // If user is not IPIAdmin...
                // ...redirect to account tab.
                next({ name: 'Admin Accounts' });
                return;
            }
        }
    });
};

/**
 * Register the navigation guard.
 * @param {Router.Router} router
 * @param {Store<ECNBState>} store
 */
export const useAuthRedirects = (router, store) => {
    // Register the navigation guard.
    router?.beforeEach(async (to, from, next) => {
        // Determine if user is authenticated.
        const authenticatedUser = isUserAuthenticated();

        // Determine if user has an account selected.
        const authorizedUser = isUserAuthorized(store);

        if (!authenticatedUser) {
            // If the user is unauthenticated...
            if (isAuthenticationRequired(to)) {
                // ...and if the destination requires authentication...
                // ...redirect to the login page.
                next({ name: 'Login' });
                return;
            }
        }

        if (authenticatedUser) {
            // If the user is authenticated...
            if (isAuthenticationSkipped(to)) {
                // ...and if the destination should be skipped when authenticated...
                // ...redirect to the home page.
                next({ name: 'Home' });
                return;
            }
            if (!authorizedUser) {
                // ...and if the does not have a selected organization/account...
                if (isAccountRequired(to)) {
                    // ...and if the destination requires an organization/account...
                    // ...redirect to the select account page.
                    next({ name: 'Select Account' });
                    return;
                }
            }
        }

        // If no guard condition is met, allow user to continue.
        next();
        return;
    });
};

// <!-- DEFAULT -->
export default { useAuthRedirects, useNARARedirects, useIPIAdminRedirects };
