import { UserPayload } from '@/payloads/v2/users';
import { Maybe } from 'true-myth/dist/maybe';
import { Account } from '@/models/v2/accounts';
import { Organization } from '@/models/v2/organizations';

/**
 * Resource model.
 * @implements {globalThis.User.Model}
 */
export class User {
    /** Get the static factory methods. */
    static get create() {
        return {
            /** @type {ModelConstructor<globalThis.User.Model, globalThis.User.Payload>} */
            fromPayload: (payload) => new User(payload),
        };
    }

    /**
     * Convert model into a payload format.
     *
     * @param {globalThis.User.Model} model
     * @returns {globalThis.User.Payload}
     */
    static createPayload(model) {
        return UserPayload.create.fromData({
            id: model.id,
            user_name: model.username,
            email: model.email,
            first_name: model.firstName,
            last_name: model.lastName,

            is_super: model.isSuperUser ? 1 : 0,
            is_protected: model.isProtected ? 1 : 0,

            created_at: model.createdAt?.toISOString(),
            updated_at: model.updatedAt?.toISOString(),
            last_login_at: model.lastLoginAt?.toISOString(),

            number_of_accounts: model.numberOfAccounts,
            number_of_organization_accounts: model.numberOfOrganizationAccounts,
            number_of_organizations: model.numberOfOrganizations,

            accounts: model.accounts.map((m) => Account.createPayload(m)),
            organizations: model.organizations.map(Organization.Model.create),
        });
    }

    /**
     * Convert model into a row data entry for the organization's admin.
     *
     * @param {globalThis.User.Model} model
     * @param {Partial<Pick<globalThis.Organization.Model, 'id' | 'access'>>} organization
     * @returns {globalThis.User.Row}
     */
    static createRowModel(model, organization = null) {
        const selectedOrganization = model.organizations.find(
            (t) => t.id === organization.id
        );
        const userRole = selectedOrganization.access?.userRole;
        return {
            id: model.id,
            email: model.email,
            username: model.username,
            firstName: model.firstName,
            lastName: model.lastName,
            lastLoginAt: model.lastLoginAt,
            createdAt: model.createdAt,
            accessType: userRole ?? 'guest',
            accounts: model?.accounts ?? [],
        };
    }

    /**
     * Construct a payload instance.
     * @param {Partial<globalThis.User.Payload>} data
     */
    constructor(data) {
        this.id = data.id;
        this.username = data.user_name;
        this.email = data.email;
        this.firstName = data.first_name;
        this.lastName = data.last_name;

        this.isSuperUser = Maybe.of(data.is_super)
            .map((value) => value === 1)
            .unwrapOr(false);
        this.isProtected = Maybe.of(data.is_protected)
            .map((value) => value === 1)
            .unwrapOr(false);

        const created_at = Maybe.of(data.created_at);
        this.createdAt = created_at.isJust ? new Date(created_at.value) : null;
        const updated_at = Maybe.of(data.updated_at);
        this.updatedAt = updated_at.isJust ? new Date(updated_at.value) : null;
        const last_login_at = Maybe.of(data.last_login_at);
        this.lastLoginAt = last_login_at.isJust
            ? new Date(last_login_at.value)
            : null;

        this.numberOfAccounts = data?.number_of_accounts ?? 0;

        this.numberOfOrganizationAccounts =
            data?.number_of_organization_accounts ?? 0;

        this.numberOfOrganizations = data?.number_of_organizations ?? 0;

        /** @type {globalThis.Organization.Model[]} */
        this.organizations = Maybe.of(data.organizations)
            .map((payloads) => payloads.map(Organization.Model.fromPayload))
            .unwrapOr([]);

        /** @type {globalThis.Account.Model[]} */
        this.accounts = Maybe.of(data.accounts)
            .map((payloads) => payloads.map((t) => new Account(t)))
            .unwrapOr([]);
    }

    /**
     * Convert model into a payload.
     */
    toPayload() {
        return User.createPayload(this);
    }

    /**
     * Convert model into a row data entry for the account's admin.
     *
     * @returns {globalThis.User.Row}
     */
    toRowModel() {
        return User.createRowModel(this);
    }
}
