// <!-- TYPES -->
import { Model } from '@/models/v1/resource/Model';

import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
import {
    LocationHierarchy,
    LocationHierarchyPayload,
    LocationHierarchyResource,
} from '@/models/v1/locations/LocationHierarchy';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'name',
    'serial',
    'minDate',
    'maxDate',
    'lastUploadDate',
    'dataLoggerManufacturer',
    'dataLoggerSerialNumber',
    'dataLoggerPlacement',
    'contactCollections',
    'contactFacilities',
    'department',
    'air',
    'bms',
    'zone',
    'photo',
    'floorplan',
    'timezone',
    'hierarchyId',
    'hierarchy',
    'naraStandard',
    'dateCreated',
    'dateUpdated',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.name, 'name'],
    [FIELDS.serial, 'serial'],
    [FIELDS.minDate, 'min_date'],
    [FIELDS.maxDate, 'max_date'],
    [FIELDS.lastUploadDate, 'last_upload'],
    [FIELDS.dataLoggerManufacturer, 'data_logger_manufacturer'],
    [FIELDS.dataLoggerSerialNumber, 'data_logger_serial_number'],
    [FIELDS.dataLoggerPlacement, 'data_logger_placement'],
    [FIELDS.contactCollections, 'collections_contact'],
    [FIELDS.contactFacilities, 'facilities_contact'],
    [FIELDS.department, 'department_or_division'],
    [FIELDS.air, 'air_source'],
    [FIELDS.bms, 'bms_id'],
    [FIELDS.zone, 'zone'],
    [FIELDS.photo, 'photo'],
    [FIELDS.floorplan, 'floorplan'],
    [FIELDS.timezone, 'timezone'],
    [FIELDS.hierarchyId, 'location_hierarchy_id'],
    [FIELDS.hierarchy, 'hierarchy'],
    [FIELDS.naraStandard, 'nara_standard'],
    [FIELDS.dateCreated, 'created_at'],
    [FIELDS.dateUpdated, 'updated_at'],
]);

/** Resource and payload keys. */
// ts-ignore
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class
 */
export class LocationPayload {
    /** @type {Number} */
    id;
    /** @type {String} */
    name;
    /** @type {String} */
    serial;
    /** @type {String} */
    min_date;
    /** @type {String} */
    max_date;
    /** @type {String} */
    last_upload;
    /** @type {String} */
    data_logger_manufacturer;
    /** @type {String} */
    data_logger_serial_number;
    /** @type {String} */
    data_logger_placement;
    /** @type {String} */
    collections_contact;
    /** @type {String} */
    facilities_contact;
    /** @type {String} */
    department_or_division;
    /** @type {String} */
    air_source;
    /** @type {String} */
    bms_id;
    /** @type {String} */
    zone;
    /** @type {String} */
    photo;
    /** @type {String} */
    floorplan;
    /** @type {String} */
    timezone;
    /** @type {Number} */
    location_hierarchy_id;
    /** @type {LocationHierarchyPayload[]} */
    hierarchy = [];
    /** @type {number} */
    nara_standard;
    /** @type {String} */
    created_at;
    /** @type {String} */
    updated_at;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<LocationPayload>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the payload.
     * @param {Location} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);
        this.serial = model.get(FIELDS.serial);
        this.min_date = model.get(FIELDS.minDate);
        this.max_date = model.get(FIELDS.maxDate);
        this.last_upload = model.get(FIELDS.lastUploadDate);
        this.data_logger_manufacturer = model.get(
            FIELDS.dataLoggerManufacturer
        );
        this.data_logger_serial_number = model.get(
            FIELDS.dataLoggerSerialNumber
        );
        this.data_logger_placement = model.get(FIELDS.dataLoggerPlacement);
        this.collections_contact = model.get(FIELDS.contactCollections);
        this.facilities_contact = model.get(FIELDS.contactFacilities);
        this.department_or_division = model.get(FIELDS.department);
        this.air_source = model.get(FIELDS.air);
        this.bms_id = model.get(FIELDS.bms);
        this.zone = model.get(FIELDS.zone);
        this.photo = model.get(FIELDS.photo);
        this.floorplan = model.get(FIELDS.floorplan);
        this.timezone = model.get(FIELDS.timezone);
        this.location_hierarchy_id = model.get(FIELDS.hierarchyId);

        /** @type {LocationHierarchy[]} */
        const hierarchy = model.get(FIELDS.hierarchy) ?? [];
        this.hierarchy = hierarchy.map((node) => node.toPayload());
        this.nara_standard = model.get(FIELDS.naraStandard);

        // Timestamps.
        this.created_at = model.get(FIELDS.dateCreated);
        this.updated_at = model.get(FIELDS.dateUpdated);

        // Return parsed instance.
        return this;
    }

    /**
     * Convert this payload into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {LocationHierarchy[]} */
        const hierarchy = this.hierarchy.map(
            (node) =>
                new LocationHierarchy({
                    payload: new LocationHierarchyPayload(node),
                })
        );

        // Return entries.
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
            [FIELDS.serial, this.serial],
            [FIELDS.minDate, this.min_date],
            [FIELDS.maxDate, this.max_date],
            [FIELDS.lastUploadDate, this.last_upload],
            [FIELDS.dataLoggerManufacturer, this.data_logger_manufacturer],
            [FIELDS.dataLoggerSerialNumber, this.data_logger_serial_number],
            [FIELDS.dataLoggerPlacement, this.data_logger_placement],
            [FIELDS.contactCollections, this.facilities_contact],
            [FIELDS.contactFacilities, this.collections_contact],
            [FIELDS.department, this.department_or_division],
            [FIELDS.air, this.air_source],
            [FIELDS.bms, this.bms_id],
            [FIELDS.zone, this.zone],
            [FIELDS.photo, this.photo],
            [FIELDS.floorplan, this.floorplan],
            [FIELDS.timezone, this.timezone],
            [FIELDS.hierarchyId, this.location_hierarchy_id],
            [FIELDS.hierarchy, hierarchy],
            [FIELDS.naraStandard, this.nara_standard],
            [FIELDS.dateCreated, this.created_at],
            [FIELDS.dateUpdated, this.updated_at],
        ];
    }
}

/**
 * @class
 */
export class LocationResource {
    /** @type {Number} */
    id;
    /** @type {String} */
    name;
    /** @type {String} */
    serial;
    /** @type {String} Stored in ISO String format. */
    minDate;
    /** @type {String} Stored in ISO String format. */
    maxDate;
    /** @type {String} Stored in ISO String format. */
    lastUploadDate;
    /** @type {String} */
    dataLoggerManufacturer;
    /** @type {String} */
    dataLoggerSerialNumber;
    /** @type {String} */
    dataLoggerPlacement;
    /** @type {String} */
    contactCollections;
    /** @type {String} */
    contactFacilities;
    /** @type {String} */
    department;
    /** @type {String} */
    air;
    /** @type {String} */
    bms;
    /** @type {String} */
    zone;
    /** @type {String} */
    photo;
    /** @type {String} */
    floorplan;
    /** @type {String} */
    timezone;
    /** @type {Number} */
    hierarchyId;
    /** @type {LocationHierarchyResource[]} */
    hierarchy = [];
    /** @type {import('@/api/v1/standards').NARAStandardResource} */
    naraStandard;
    /** @type {String} */
    dateCreated;
    /** @type {String} */
    dateUpdated;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<LocationResource>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Get the path from the location resource.
     * @param {LocationResource} resource
     */
    static getPath(resource) {
        const separator = '/';
        if (resource.hierarchyId >= 0) {
            // Filter for distinct hierarchies.
            const distinct = resource.hierarchy.filter(
                (node, index) =>
                    index ===
                    resource.hierarchy.findIndex((el) => el.id === node.id)
            );
            // Map distinct hierarchies into path names.
            const levels = distinct.map((node) => node.name);
            return levels.join(separator);
        } else {
            return null;
        }
    }

    /**
     * Get the label from the location resource.
     * @param {LocationResource} resource
     */
    static getLabel(resource) {
        const name =
            resource.name?.length > 0 ? resource.name : '<Untitled Location>';
        const path =
            LocationResource.getPath(resource)?.length > 0
                ? LocationResource.getPath(resource)
                : '<Unassigned>';
        const prefix = `${path}/${name}`;
        const serial =
            resource.dataLoggerSerialNumber?.length > 0
                ? resource.dataLoggerSerialNumber
                : '';
        return `${prefix} [${serial}]`;
    }

    /**
     * Parse model state to get the resource.
     * @param {Location} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);
        this.serial = model.get(FIELDS.serial);
        this.minDate = model.get(FIELDS.minDate);
        this.maxDate = model.get(FIELDS.maxDate);
        this.lastUploadDate = model.get(FIELDS.lastUploadDate);
        this.dataLoggerManufacturer = model.get(FIELDS.dataLoggerManufacturer);
        this.dataLoggerSerialNumber = model.get(FIELDS.dataLoggerSerialNumber);
        this.dataLoggerPlacement = model.get(FIELDS.dataLoggerPlacement);
        this.contactCollections = model.get(FIELDS.contactCollections);
        this.contactFacilities = model.get(FIELDS.contactFacilities);
        this.department = model.get(FIELDS.department);
        this.air = model.get(FIELDS.air);
        this.bms = model.get(FIELDS.bms);
        this.zone = model.get(FIELDS.zone);
        this.photo = model.get(FIELDS.photo);
        this.floorplan = model.get(FIELDS.floorplan);
        this.timezone = model.get(FIELDS.timezone).timezone;
        this.hierarchyId = model.get(FIELDS.hierarchyId);

        /** @type {LocationHierarchy[]} */
        const hierarchy = model.get(FIELDS.hierarchy) ?? [];
        this.hierarchy = hierarchy.map((node) => node.toResource());
        this.naraStandard = model.get(FIELDS.naraStandard);

        // TIMESTAMPS
        this.dateCreated = model.get(FIELDS.dateCreated);
        this.dateUpdated = model.get(FIELDS.dateUpdated);

        // Return parsed instance.
        return this;
    }

    /**
     * Convert this resource into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {LocationHierarchy[]} */
        const hierarchy = this.hierarchy.map(
            (node) =>
                new LocationHierarchy({
                    resource: new LocationHierarchyResource(node),
                })
        );

        // Return entries.
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
            [FIELDS.serial, this.serial],
            [FIELDS.minDate, this.minDate],
            [FIELDS.maxDate, this.maxDate],
            [FIELDS.lastUploadDate, this.lastUploadDate],
            [FIELDS.dataLoggerManufacturer, this.dataLoggerManufacturer],
            [FIELDS.dataLoggerSerialNumber, this.dataLoggerSerialNumber],
            [FIELDS.dataLoggerPlacement, this.dataLoggerPlacement],
            [FIELDS.contactCollections, this.contactCollections],
            [FIELDS.contactFacilities, this.contactFacilities],
            [FIELDS.department, this.department],
            [FIELDS.air, this.air],
            [FIELDS.bms, this.bms],
            [FIELDS.zone, this.zone],
            [FIELDS.photo, this.photo],
            [FIELDS.floorplan, this.floorplan],
            [FIELDS.timezone, this.timezone],
            [FIELDS.hierarchyId, this.hierarchyId],
            [FIELDS.hierarchy, hierarchy],
            [FIELDS.naraStandard, this.naraStandard],
            [FIELDS.dateCreated, this.dateCreated],
            [FIELDS.dateUpdated, this.dateUpdated],
        ];
    }

    /**
     * Get the location path using the hierarchy.
     * @returns {String}
     */
    get path() {
        return LocationResource.getPath(this);
    }

    /**
     * Get the full location path and name.
     * @returns {String}
     */
    get label() {
        return LocationResource.getLabel(this);
    }
}

/**
 * @class
 * @extends {Model<LocationPayload, LocationResource>}
 */
export class Location extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<LocationPayload>} payload
     * @returns {this}
     */
    parsePayload(payload) {
        const instance = new LocationPayload(payload);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {LocationPayload}
     */
    toPayload() {
        return new LocationPayload().parseModel(this);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<LocationResource>} resource
     * @returns {this}
     */
    parseResource(resource) {
        const instance = new LocationResource(resource);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {LocationResource}
     */
    toResource() {
        return new LocationResource().parseModel(this);
    }
}
