// <!-- INTERFACES -->
import { ModelResource } from '@/utils/resources';
import { ModelAttributeFactory } from '@/utils/resources';
import { OrganizationPayload } from '@/payloads/v2/organizations/OrganizationPayload';

// <!-- RELATIONSHIPS -->
import { SubscriptionModel } from '@/models/v2/subscriptions/SubscriptionModel';
import { AccessModel } from './AccessModel';

// <!-- ENUMS -->
import { ReminderFrequency, TemperatureScale } from '@/enums';

// <!-- UTILITIES ->
import { Maybe } from 'true-myth/dist/maybe';
import collect from 'collect.js';
import { TimezonePayload } from '@/payloads/v2/timezones';

/**
 * Create a specialized resource instance.
 *
 * @extends {ModelResource<PayloadData,ModelData>}
 * @implements {Resource.Model<PayloadData,ModelData>}
 * @implements {ModelData}
 */
export class OrganizationModel extends ModelResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Organization.Payload} PayloadData */
    /** @typedef {Organization.Model} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} attributes
     * @returns {OrganizationModel}
     */
    static create(attributes) {
        return new OrganizationModel(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} data
     * @returns {OrganizationModel}
     */
    static fromPayload(data) {
        const attributes = OrganizationModel.attributesFromPayload(data);
        return OrganizationModel.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<ModelData>} */
    static get defaults() {
        return {
            id: null,
            name: null,
            email: {
                billing: null,
                contact: null,
            },
            address: {
                city: '',
                state: '',
                country: '',
            },
            treeLabels: ['Site', 'Building', 'Floor', 'Room'],
            timezone: TimezonePayload.create({}).toValue(),
            temperatureScale: TemperatureScale.Fahrenheit,
            reminderFrequency: ReminderFrequency.Never,
            lastReminderAt: null,
            createdAt: null,
            updatedAt: null,
            numberOfAccounts: null,
            numberOfUsers: null,
            numberOfSubscriptions: null,
            numberOfCurrentSubscriptions: null,
            numberOfLocations: null,
            access: AccessModel.create({}),
            currentSubscriptions: [],
        };
    }

    /**
     * Create resource attributes from the payload data.
     *
     * @param {Partial<PayloadData>} payload
     * @returns {Partial<ModelData>}
     */
    static attributesFromPayload(payload = {}) {
        // Get transformer functions.
        const { coalesce, parse } = this;

        // Transform attributes.
        const currentSubscriptions = payload.current_subscriptions?.map(
            SubscriptionModel.fromPayload
        );

        // Return the created state.
        return {
            id: payload.id,
            name: payload.name,
            email: {
                billing: payload.billing_email,
                contact: payload.contact_email,
            },
            address: {
                city: payload.city ?? '',
                state: payload.state ?? '',
                country: payload.country ?? '',
            },
            treeLabels:
                payload.tree_labels?.length === 4
                    ? [...payload.tree_labels]
                    : null,
            timezone: TimezonePayload.create(payload.timezone).toValue(),
            temperatureScale: payload.temperature_scale,
            reminderFrequency: payload.reminder_frequency,
            lastReminderAt: parse.dateString(payload.last_reminder_at),
            createdAt: parse.dateString(payload.created_at),
            updatedAt: parse.dateString(payload.updated_at),
            numberOfAccounts: coalesce.count(payload.number_of_accounts),
            numberOfUsers: coalesce.count(payload.number_of_users),
            numberOfSubscriptions: coalesce.count(
                payload.number_of_subscriptions
            ),
            numberOfCurrentSubscriptions: coalesce.count(
                payload.number_of_current_subscriptions
            ),
            numberOfLocations: coalesce.count(payload.number_of_locations),
            access: AccessModel.fromPayload(payload.access),
            currentSubscriptions,
        };
    }

    /**
     * Create row model from model data.
     *
     * @param {Partial<ModelData>} data
     * @returns {Organization.Row}
     */
    static createRowModel(data) {
        // Get the data.
        const attributes = Object.assign(OrganizationModel.defaults, data);

        // Find primary email.
        const billingEmail = attributes.email?.billing;
        const contactEmail = attributes.email?.contact;
        const primaryEmail = billingEmail ?? contactEmail;

        // Find current subscription.
        // Defaults to highest priced active, non-expired subscription.
        const subscriptions = collect(attributes.currentSubscriptions);
        const subscription = Maybe.of(
            subscriptions.sortByDesc('currentPrice').first()
        );

        // Extract relevant details.
        const subscriptionLevel = subscription.isJust
            ? subscription.value?.plan?.name
            : 'None';
        const subscriptionPrice = subscription.isJust
            ? subscription.value?.pricePerYear
            : 0;
        const expirationDate = subscription.isJust
            ? subscription.value?.expireAt
            : null;

        // Return the row model.
        return {
            id: attributes.id,
            name: attributes.name,
            billingEmail,
            contactEmail,
            primaryEmail,
            city: attributes.address?.city,
            state: attributes.address?.state,
            country: attributes.address?.country,
            treeLabels: attributes.treeLabels,
            reminderFrequency: attributes.reminderFrequency,
            temperatureScale: attributes.temperatureScale,
            timezone: attributes.timezone,
            numberOfAccounts: attributes.numberOfAccounts,
            numberOfUsers: attributes.numberOfUsers,
            numberOfSubscriptions: attributes.numberOfSubscriptions,
            numberOfCurrentSubscriptions:
                attributes.numberOfCurrentSubscriptions,
            numberOfLocations: attributes.numberOfLocations,
            subscriptionLevel,
            subscriptionPrice,
            creationDate: attributes.createdAt,
            expirationDate,
            lastReminderDate: attributes.lastReminderAt,
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<ModelData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            ModelAttributeFactory.create(
                OrganizationModel.attributesFromPayload,
                OrganizationModel.defaults
            )
        );

        // Hydrate sub-resource.
        if (this.exists('access') && !(this.access instanceof AccessModel)) {
            this.access = AccessModel.create(this.access);
        }
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Organization\\Model';
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a resource. */
    toPayload() {
        return OrganizationPayload.fromModel(this);
    }

    /** Get shallow copy of this instance as a resource. */
    toModel() {
        return this.clone();
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (OrganizationModel.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get name() {
        return this.get('name');
    }

    set name(value) {
        this.set('name', value);
    }

    get email() {
        return this.get('email');
    }

    set email(value) {
        this.set('email', value);
    }

    get address() {
        return this.get('address');
    }

    set address(value) {
        this.set('address', value);
    }

    get treeLabels() {
        return this.get('treeLabels');
    }

    set treeLabels(value) {
        this.set('treeLabels', value);
    }

    get timezone() {
        return this.get('timezone');
    }

    set timezone(value) {
        this.set('timezone', value);
    }

    get temperatureScale() {
        return this.get('temperatureScale');
    }

    set temperatureScale(value) {
        this.set('temperatureScale', value);
    }

    get reminderFrequency() {
        return this.get('reminderFrequency');
    }

    set reminderFrequency(value) {
        this.set('reminderFrequency', value);
    }

    get lastReminderAt() {
        return this.get('lastReminderAt');
    }

    // set lastReminderAt(value) {
    //     this.set('lastReminderAt', value);
    // }

    get createdAt() {
        return this.get('createdAt');
    }

    // set createdAt(value) {
    //     this.set('createdAt', value);
    // }

    get updatedAt() {
        return this.get('updatedAt');
    }

    // set updatedAt(value) {
    //     this.set('updatedAt', value);
    // }

    get numberOfAccounts() {
        return this.get('numberOfAccounts');
    }

    // set numberOfAccounts(value) {
    //     this.set('numberOfAccounts', value);
    // }

    get numberOfUsers() {
        return this.get('numberOfUsers');
    }

    // set numberOfUsers(value) {
    //     this.set('numberOfUsers', value);
    // }

    get numberOfSubscriptions() {
        return this.get('numberOfSubscriptions');
    }

    // set numberOfSubscriptions(value) {
    //     this.set('numberOfSubscriptions', value);
    // }

    get numberOfCurrentSubscriptions() {
        return this.get('numberOfCurrentSubscriptions');
    }

    // set numberOfCurrentSubscriptions(value) {
    //     this.set('numberOfCurrentSubscriptions', value);
    // }

    get numberOfLocations() {
        return this.get('numberOfLocations');
    }

    // set numberOfLocations(value) {
    //     this.set('numberOfLocations', value);
    // }

    get access() {
        return this.get('access');
    }

    set access(value) {
        this.set('access', value);
    }

    get currentSubscriptions() {
        return this.get('currentSubscriptions');
    }

    set currentSubscriptions(value) {
        this.set('currentSubscriptions', value);
    }
}
