// <!-- API -->
import { UploadData, UploadRecord } from '@/store/types/uploader/state';
import {
    UploadDataMutations,
    UploadRecordMutations,
} from '@/store/types/uploader/mutations';
import { UploadDataGetters } from '@/store/types/uploader/getters';
import {
    UploadConfigurationActions,
    UploadDataActions,
    UploadRecordActions,
} from '@/store/types/uploader/actions';
import isNil from 'lodash-es/isNil';

// <!-- UTILITIES -->
import { isPEM1File, isPEM2File } from '@/utils/file';

// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
import { MappingProfile } from '@/models/v1/mappings/MappingProfile';
import { UploadStatusID } from '@/store/types/uploader/state/UploadData';
import {
    UploadRecordStatusIDs,
    UploadRecordFlagIDs,
} from '@/store/types/uploader/state/UploadRecord';
import { Assertion } from '../../ECNBAssertions';
import locations from '@/api/v1/accounts/locations';
import accountDatasets from '@/api/v1/accounts/datasets';
import profiles from '@/api/v1/accounts/mappings';
// ts-ignore
import collect, { Collection } from 'collect.js';
import ActionLogger from '@/utils/ActionLogger';

// CLASS
/**
 * @class
 * Uploader module.
 */
export class UploadDataModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'uploader/data';
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        // EXPOSE
        return {
            namespaced: true,
            state: () => new UploadData(),
            get modules() {
                return {};
            },
            get getters() {
                const $getters = UploadDataGetters;
                return {
                    ...$getters,
                };
            },
            get mutations() {
                const $data = UploadDataMutations;
                const $record = UploadRecordMutations;
                const data = {
                    get setters() {
                        const $set = $data.set;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {UploadRecord} record
                             */
                            replaceRecord: (state, record) => {
                                $set(state).record.ifPresent(record);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get clearers() {
                        const $drop = $data.drop;
                        return {
                            /**
                             * @param {UploadData} state
                             */
                            clearModule: (state) => {
                                $drop(state).state();
                            },
                            /**
                             * @param {UploadData} state
                             * @param {'all' | keyof UploadStatusID} id
                             */
                            clearStatus: (state, id) => {
                                if (id === 'all') {
                                    $drop(state).status.all();
                                    // state.status = new Set(state.status.keys());
                                    return;
                                }
                                $drop(state).status.byID(id);
                                // state.status = new Set(state.status.keys());
                                return;
                            },
                            /**
                             * @param {UploadData} state
                             * @param {'all' | ((record?: UploadRecord, index?: Number) => Boolean)} [filter]
                             */
                            clearRecords: (state, filter = 'all') => {
                                if (filter === 'all') {
                                    $drop(state).records.all();
                                    // state.records = new Map(
                                    //     state.records.entries()
                                    // );
                                    return;
                                }
                                $drop(state).records.where(filter);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                                return;
                            },
                        };
                    },
                    get adders() {
                        const $add = $data.add;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {UploadRecord} record
                             */
                            upsertRecord: (state, record) => {
                                $add(state).record.ifMissing(record);
                                $add(state).record.ifPresent(record);
                            },
                            /**
                             * @param {UploadData} state
                             * @param {UploadRecord} record
                             */
                            insertRecord: (state, record) => {
                                $add(state).record.ifMissing(record);
                            },
                            /**
                             * @param {UploadData} state
                             * @param {UploadRecord} record
                             */
                            mergeRecord: (state, record) => {
                                $add(state).record.ifPresent(record);
                            },
                            /**
                             * @param {UploadData} state
                             * @param {keyof UploadStatusID} id
                             */
                            enableStatus: (state, id) => {
                                $add(state).status(id);
                            },
                        };
                    },
                    get droppers() {
                        const $drop = $data.drop;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ record?: UploadRecord, filename?: String, id?: Number }} target
                             */
                            dropRecord: (state, target) => {
                                const { record, filename, id } = target ?? {};
                                if (!isNil(record)) {
                                    $drop(state).record.byFilename(
                                        record.filename
                                    );
                                    return;
                                }
                                if (!isNil(filename)) {
                                    $drop(state).record.byFilename(filename);
                                    return;
                                }
                                if (!isNil(id)) {
                                    $drop(state).record.byDatasetBatchID(id);
                                    return;
                                }
                            },
                            /**
                             * @param {UploadData} state
                             * @param {String} filename
                             */
                            dropRecordByFilename: (state, filename) => {
                                const record = state.records.get(filename);
                                if (!isNil(record)) {
                                    $drop(state).record.byFilename(
                                        record?.filename
                                    );
                                }
                            },
                            /**
                             * @param {UploadData} state
                             * @param {Number} id
                             */
                            dropRecordByBatchID: (state, id) => {
                                const record = state.all
                                    .all()
                                    .find(
                                        (r) =>
                                            r.batch.exists &&
                                            r.batch.value.id === id
                                    );
                                if (!isNil(record)) {
                                    $drop(state).record.byDatasetBatchID(
                                        record?.batch?.value?.id
                                    );
                                }
                            },
                            /**
                             * @param {UploadData} state
                             * @param {keyof UploadStatusID} id
                             */
                            disableStatus: (state, id) => {
                                $drop(state).status.byID(id);
                            },
                        };
                    },
                    get changers() {
                        const $set = $data.set;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ id: keyof UploadStatusID, value?: Boolean }} payload
                             */
                            changeStatus: (state, payload) => {
                                const { id, value = false } = payload ?? {};
                                $set(state).status(id).to(value);
                            },
                        };
                    },
                };
                const record = {
                    get setters() {
                        const $set = $record.set;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, file: File, strategy: 'transient' | 'persisted' }} payload
                             */
                            // ts-ignore
                            setRecordFile: (state, payload) => {
                                const { record, file, strategy } = payload;
                                switch (strategy) {
                                    case 'transient':
                                        $set(record).file(file).asTransient();
                                        break;
                                    case 'persisted':
                                        $set(record).file(file).asPersisted();
                                        break;
                                }
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, location: LocationResource, strategy: 'transient' | 'persisted' | 'suggested'  }} payload
                             */
                            // ts-ignore
                            setRecordLocation: (state, payload) => {
                                const { record, location, strategy } = payload;
                                switch (strategy) {
                                    case 'transient':
                                        $set(record)
                                            .location(location)
                                            .asTransient();
                                        break;
                                    case 'persisted':
                                        $set(record)
                                            .location(location)
                                            .asPersisted();
                                        break;
                                    case 'suggested':
                                        $set(record)
                                            .location(location)
                                            .asSuggestion();
                                        break;
                                }
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, profile: import('@/models/v1/mappings/MappingProfile').MappingProfileResource, strategy: 'transient' | 'persisted' | 'suggested' }} payload
                             */
                            // ts-ignore
                            setRecordMappingProfile: (state, payload) => {
                                const { record, profile, strategy } = payload;
                                switch (strategy) {
                                    case 'transient':
                                        $set(record)
                                            .profile(profile)
                                            .asTransient();
                                        break;
                                    case 'persisted':
                                        $set(record)
                                            .profile(profile)
                                            .asPersisted();
                                        break;
                                    case 'suggested':
                                        $set(record)
                                            .profile(profile)
                                            .asSuggestion();
                                        break;
                                }
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, batch: import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource, strategy: 'transient' | 'persisted' }} payload
                             */
                            // ts-ignore
                            setRecordDatasetBatch: (state, payload) => {
                                const { record, batch, strategy } = payload;
                                switch (strategy) {
                                    case 'transient':
                                        $set(record).batch(batch).asTransient();
                                        break;
                                    case 'persisted':
                                        $set(record).batch(batch).asPersisted();
                                        break;
                                }
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, contents: Array<{ line: Number, data: String[] }> }} payload
                             */
                            // ts-ignore
                            setRecordDatasetContents: (state, payload) => {
                                const { record, contents } = payload;
                                $set(record).contents(contents);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get clearers() {
                        const $drop = $record.drop;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {UploadRecord} record
                             */
                            // ts-ignore
                            clearRecordState: (state, record) => {
                                $drop(record).state();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {UploadRecord} record
                             */
                            // ts-ignore
                            clearRecordStatus: (state, record) => {
                                $drop(record).status.all();
                                // state.status = new Set(state.status.keys());
                            },
                        };
                    },
                    get adders() {
                        const $add = $record.add;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, id: keyof UploadRecordStatusIDs }} payload
                             */
                            // ts-ignore
                            enableRecordStatus: (state, payload) => {
                                const { record, id } = payload;
                                $add(record).status(id);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, id: keyof UploadRecordFlagIDs }} payload
                             */
                            // ts-ignore
                            enableRecordFlag: (state, payload) => {
                                const { record, id } = payload;
                                $add(record).flag(id);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get droppers() {
                        const $drop = $record.drop;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            dropRecordFile: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $drop(record).file.property();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            dropRecordLocation: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $drop(record).location.property();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            dropRecordSuggestedLocation: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $drop(record).location.suggestion();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            dropRecordMappingProfile: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $drop(record).profile.property();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            dropRecordSuggestedMappingProfile: (
                                state,
                                payload
                            ) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $drop(record).profile.suggestion();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            dropRecordDatasetBatch: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $drop(record).batch.property();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            dropRecordDatasetContents: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $drop(record).contents();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, id: 'all' | keyof UploadRecordStatusIDs }} payload
                             */
                            // ts-ignore
                            disableRecordStatus: (state, payload) => {
                                const { record, id } = payload;
                                if (id === 'all') {
                                    $drop(record).status.all();
                                    // state.records = new Map(
                                    //     state.records.entries()
                                    // );
                                    return;
                                }
                                $drop(record).status.byID(id);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                                return;
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, id: 'all' | keyof UploadRecordFlagIDs }} payload
                             */
                            // ts-ignore
                            disableRecordFlag: (state, payload) => {
                                const { record, id } = payload;
                                if (id === 'all') {
                                    $drop(record).flag.all();
                                    // state.records = new Map(
                                    //     state.records.entries()
                                    // );
                                    return;
                                }
                                $drop(record).flag.byID(id);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                                return;
                            },
                        };
                    },
                    get changers() {
                        const $set = $record.set;
                        const $change = $record.change;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, id: keyof UploadRecordStatusIDs, value?: Boolean }} payload
                             */
                            // ts-ignore
                            changeRecordStatus: (state, payload) => {
                                const { record, id, value } = payload;
                                $set(record).status(id).to(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ record: UploadRecord, id: keyof UploadRecordFlagIDs, value?: Boolean }} payload
                             */
                            // ts-ignore
                            changeRecordFlag: (state, payload) => {
                                const { record, id, value } = payload;
                                $set(record).flag(id).to(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            markRecord: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).flags.marked.enable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            unmarkRecord: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).flags.marked.disable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            startRecordUpload: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.uploading.enable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            stopRecordUpload: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.uploading.disable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            startRecordViewing: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.viewing.enable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            stopRecordViewing: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.viewing.disable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            startRecordEditing: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.editing.enable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            stopRecordEditing: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.editing.disable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            startRecordIngest: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.uploading.enable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String }} payload
                             */
                            stopRecordIngest: (state, payload) => {
                                const { filename } = payload;
                                const record = state.records.get(filename);
                                $change(record).status.uploading.disable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get selectors() {
                        const $select = $record.select;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ value: File }} payload
                             */
                            selectFile: (state, payload) => {
                                const { value } = payload;
                                const filename = value.name;
                                const record = state.records.get(filename);
                                $select(record).file(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, value: LocationResource }} payload
                             */
                            selectLocation: (state, payload) => {
                                const { filename, value } = payload;
                                const record = state.records.get(filename);
                                $select(record).location(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, value: import('@/models/v1/mappings/MappingProfile').MappingProfileResource }} payload
                             */
                            selectMappingProfile: (state, payload) => {
                                const { filename, value } = payload;
                                const record = state.records.get(filename);
                                $select(record).profile(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, value: import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource }} payload
                             */
                            selectDatasetBatch: (state, payload) => {
                                const { filename, value } = payload;
                                const record = state.records.get(filename);
                                $select(record).batch(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get suggesters() {
                        const $suggest = $record.suggest;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, value: LocationResource }} payload
                             */
                            suggestLocation: (state, payload) => {
                                const { filename, value } = payload;
                                const record = state.records.get(filename);
                                $suggest(record).location(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, value: import('@/models/v1/mappings/MappingProfile').MappingProfileResource }} payload
                             */
                            suggestMappingProfile: (state, payload) => {
                                const { filename, value } = payload;
                                const record = state.records.get(filename);
                                $suggest(record).profile(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get assigners() {
                        const $assign = $record.assign;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ value: File }} payload
                             */
                            assignFile: (state, payload) => {
                                const { value } = payload;
                                const filename = value.name;
                                const record = state.records.get(filename);
                                $assign(record).file(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, value: LocationResource }} payload
                             */
                            assignLocation: (state, payload) => {
                                const { filename, value } = payload;
                                const record = state.records.get(filename);
                                $assign(record).location(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get uploaders() {
                        const $upload = $record.upload;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, batch: import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource, contents: Array<{ line: Number, data: String[] }>, file: File, location: LocationResource, type: String }} payload
                             */
                            storeUploadedRecord: (state, payload) => {
                                const {
                                    filename,
                                    batch,
                                    contents,
                                    file,
                                    location,
                                    type,
                                } = payload;
                                const record = state.records.get(filename);
                                $upload(record).store(
                                    batch,
                                    contents,
                                    file,
                                    location,
                                    type,
                                );
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get appliers() {
                        const $set = $record.set;
                        const $apply = $record.apply;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, value: import('@/models/v1/mappings/MappingProfile').MappingProfileResource }} payload
                             */
                            createMappingProfile: (state, payload) => {
                                const { filename, value } = payload;
                                const record = state.records.get(filename);
                                $apply(record).profile(value);
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, profile: import('@/models/v1/mappings/MappingProfile').MappingProfileResource, batch: import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource }} payload
                             */
                            applyMappingProfile: (state, payload) => {
                                const { filename, profile, batch } = payload;
                                const record = state.records.get(filename);
                                $apply(record).profile(profile);
                                $set(record).batch(batch).asPersisted();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                    get ingesters() {
                        const $change = $record.change;
                        const $ingest = $record.ingest;
                        return {
                            /**
                             * @param {UploadData} state
                             * @param {{ filename: String, batch: import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource, profile: import('@/models/v1/mappings/MappingProfile').MappingProfileResource }} payload
                             */
                            storeIngestedRecord: (state, payload) => {
                                const { filename, batch, profile } = payload;
                                const record = state.records.get(filename);
                                $ingest(record).store(batch, profile);
                                $change(record).flags.ingested.enable();
                                // state.records = new Map(
                                //     state.records.entries()
                                // );
                            },
                        };
                    },
                };
                return {
                    ...data.setters,
                    ...data.clearers,
                    ...data.adders,
                    ...data.droppers,
                    ...data.changers,
                    ...record.setters,
                    ...record.adders,
                    ...record.clearers,
                    ...record.droppers,
                    ...record.changers,
                    ...record.selectors,
                    ...record.suggesters,
                    ...record.assigners,
                    ...record.uploaders,
                    ...record.appliers,
                    ...record.ingesters,
                };
            },
            get actions() {
                // ts-ignore
                const $use = ECNBModule.use;
                // ts-ignore
                const $data = UploadDataActions;
                // ts-ignore
                const $record = UploadRecordActions;
                const $assert = {
                    config: UploadConfigurationActions.assert,
                    data: UploadDataActions.assert,
                    record: UploadRecordActions.assert,
                };
                const lifecycle = {
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {*} payload
                     */
                    // ts-ignore
                    initializeUploadForm: (context, payload) => {
                        throw new Error(`Not implemented.`);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {*} payload
                     */
                    // ts-ignore
                    resetUploadForm: (context, payload) => {
                        const { commit } = context;
                        commit(`clearRecords`);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {*} payload
                     */
                    // ts-ignore
                    restartUploadForm: (context, payload) => {
                        // ts-ignore
                        const { commit } = context;
                        throw new Error(`Not implemented.`);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {*} payload
                     */
                    // ts-ignore
                    resolveUploadForm: (context, payload) => {
                        throw new Error(`Not implemented.`);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {*} payload
                     */
                    // ts-ignore
                    rejectUploadForm: (context, payload) => {
                        throw new Error(`Not implemented.`);
                    },
                };
                const drop = {
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     */
                    clearRecords: async (context) => {
                        const { commit } = context;
                        commit(`clearRecords`, 'all');
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String }} payload
                     */
                    dropRecord: async (context, payload) => {
                        const { state, commit } = context;
                        const { filename } = payload;
                        await Assertion.expect(filename).isNotNil();
                        await Assertion.expect(
                            state.records.get(filename)
                        ).isNotNil();
                        commit(`dropRecordByFilename`, filename);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     */
                    dropRecordsWhereMarkedForRemoval: async (context) => {
                        const { commit } = context;
                        /** @type {(record?: UploadRecord, index?: Number) => Boolean} */
                        const isRecordMarked = (record) => {
                            return !isNil(record) && record.isMarkedForRemoval;
                        };
                        commit(`clearRecords`, isRecordMarked);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String }} payload
                     */
                    dropRecordLocation: async (context, payload) => {
                        const { state, commit } = context;
                        const { filename } = payload;
                        await Assertion.expect(filename).isNotNil();
                        await Assertion.expect(
                            state.records.get(filename)
                        ).isNotNil();
                        commit(`dropRecordLocation`, { filename });
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String }} payload
                     */
                    dropRecordMappingProfile: async (context, payload) => {
                        const { state, commit } = context;
                        const { filename } = payload;
                        await Assertion.expect(filename).isNotNil();
                        await Assertion.expect(
                            state.records.get(filename)
                        ).isNotNil();
                        commit(`dropRecordMappingProfile`, { filename });
                    },
                };
                const select = {
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ file: File }} payload
                     */
                    selectRecordFile: async (context, payload) => {
                        const { state, rootState, commit } = context;
                        const { file } = payload;
                        const $assertConfig = $assert.config({
                            ...context,
                            state: rootState.uploader.config,
                        });
                        await $assertConfig.filesize(file.size);
                        await $assertConfig.filetype(file.type);
                        await $assertConfig.belowOrAtCapacity(state.count + 1);
                        await $assert.data(state).isMissing(file);
                        await $assert.data(state).data.file.isNotSelected(file);
                        // Create and insert record with selected file.
                        const record = new UploadRecord();
                        record.file.commit(file);
                        // Disable the record flag by default.
                        record.flags.delete('pem');
                        // If PEM filetype inferred...
                        if (
                            file.type?.length === 0 &&
                            file.name?.length > 0 &&
                            file.name.includes('.') &&
                            (isPEM2File(file.name) || isPEM1File(file.name))
                        ) {
                            // Set the record flag.
                            record.flags.add('pem');
                        }
                        commit('insertRecord', record);
                        commit('selectFile', { value: file });
                        return state.records.get(file.name);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String, location: LocationResource }} payload
                     */
                    selectRecordLocation: async (context, payload) => {
                        const { state, commit } = context;
                        const { filename, location } = payload;
                        await $assert.data(state).withFilename(filename);
                        commit('selectLocation', { filename, value: location });
                        return state.records.get(filename);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String, profile: import('@/models/v1/mappings/MappingProfile').MappingProfileResource }} payload
                     */
                    selectRecordMappingProfile: async (context, payload) => {
                        const { state, commit } = context;
                        const { filename, profile } = payload;
                        await $assert.data(state).withFilename(filename);
                        commit('selectMappingProfile', {
                            filename,
                            value: profile,
                        });
                        return state.records.get(filename);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String, batch: import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource}} payload
                     */
                    selectRecordDatasetBatch: async (context, payload) => {
                        const { state, commit } = context;
                        const { filename, batch } = payload;
                        await $assert.data(state).withFilename(filename);
                        commit('selectDatasetBatch', {
                            filename,
                            value: batch,
                        });
                        return state.records.get(filename);
                    },
                };
                const suggest = {
                    /**
                     * Request set of suggested locations for all records in the store.
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     */
                    suggestRecordLocations: async (context) => {
                        const { state, rootState, commit } = context;
                        const { accounts, cache } = rootState;
                        const hasLocations = !cache.locations.is.empty;
                        const isSuggesting = state.isSuggestingLocations;
                        await Assertion.expect(hasLocations).isTruthy();
                        await Assertion.expect(isSuggesting).isFalsy();
                        try {
                            commit(`enableStatus`, `suggesting_locations`);
                            // ===== FETCH =====
                            const { account } = accounts;
                            const filenames = [...state.records.values()].map(
                                (r) => r.filename
                            );
                            const { suggestedLocations } =
                                await locations.suggestLocations(
                                    account,
                                    filenames
                                );
                            // ===== MAP =====
                            /** @type {Map<String, LocationResource>} Location suggestions. */
                            const suggestions = new Map(
                                Object.entries(suggestedLocations).map(
                                    ([filename, id]) => {
                                        const location = isNil(id)
                                            ? null
                                            : cache.locations.index.get(id);
                                        /** @type {[ filename: String, location: LocationResource ]} */
                                        const entry = [filename, location];
                                        return entry;
                                    }
                                )
                            );
                            // ===== COMMIT =====
                            for (const [filename, suggestion] of suggestions) {
                                // Commit update for each record with a passed suggestion, if it is non-null.
                                if (!isNil(suggestion)) {
                                    commit(`suggestLocation`, {
                                        filename,
                                        value: suggestion,
                                    });
                                }
                            }
                            // ==== EXPOSE ====
                            return suggestions;
                        } finally {
                            commit(`disableStatus`, `suggesting_locations`);
                        }
                    },
                    /**
                     * Request set of suggested profiles for all records in the store.
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     */
                    suggestRecordMappingProfiles: async (context) => {
                        const { state, rootState, commit } = context;
                        const { accounts, cache } = rootState;
                        const hasLocations = !cache.locations.is.empty;
                        const hasProfiles = !cache.profiles.is.empty;
                        const isSuggesting = state.isSuggestingMappingProfiles;
                        await Assertion.expect(hasLocations).isTruthy();
                        await Assertion.expect(hasProfiles).isTruthy();
                        await Assertion.expect(isSuggesting).isFalsy();
                        try {
                            commit(
                                `enableStatus`,
                                `suggesting_mapping_profiles`
                            );
                            // ===== FETCH =====
                            const { account } = accounts;
                            const request = [...state.records.values()].map(
                                (r) => ({
                                    filename: r.filename,
                                    location: r.location.value.id,
                                })
                            );
                            const collection = await profiles.suggestProfiles(
                                account,
                                request
                            );
                            // ===== MAP =====
                            /** @type {Map<Number, Map<String, import('@/models/v1/mappings/MappingProfile').MappingProfileResource>>} Mapping profile suggestions. */
                            const suggestions = new Map();
                            for (const [
                                location,
                                suggestedProfiles,
                            ] of collection) {
                                /** @type {Map<String, import('@/models/v1/mappings/MappingProfile').MappingProfileResource>} */
                                const hydrated = new Map();
                                for (const [
                                    filename,
                                    id,
                                ] of suggestedProfiles) {
                                    // ===== COMMIT =====
                                    // - Commit update for each record with a passed suggestion, if it is non-null.
                                    const suggestion = isNil(id)
                                        ? null
                                        : cache.profiles.index.get(id);
                                    if (!isNil(suggestion)) {
                                        commit(`suggestMappingProfile`, {
                                            filename,
                                            value: suggestion,
                                        });
                                    }
                                    hydrated.set(filename, suggestion);
                                }
                                suggestions.set(location, hydrated);
                            }

                            // ==== EXPOSE ====
                            return suggestions;
                        } finally {
                            commit(
                                `disableStatus`,
                                `suggesting_mapping_profiles`
                            );
                        }
                    },
                };
                const create = {
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String }} payload
                     */
                    createRecordMappingProfile: async (context, payload) => {
                        const { state, rootState, commit, dispatch } = context;
                        const { accounts } = rootState;
                        const { filename } = payload;
                        const records = state.records;
                        const record = records.get(filename);
                        await Assertion.expect(record).isNotNil();
                        await Assertion.expect(
                            record.mappingProfile.exists
                        ).isTruthy();
                        await Assertion.expect(
                            record.isCreatingMappingProfile
                        ).isFalsy();
                        await Assertion.expect(
                            record.isMappingProfileCreated
                        ).isFalsy();
                        try {
                            commit(`enableRecordStatus`, {
                                record,
                                id: 'creating_mapping_profile',
                            });
                            const profile = record.mappingProfile.value;
                            if (profile.id === -1) {
                                // ==== CREATE ====
                                const { account } = accounts;
                                const payload = new MappingProfile({
                                    resource: profile,
                                }).toPayload();
                                const response =
                                    await profiles.createMappingProfile(
                                        account,
                                        payload
                                    );
                                // ==== STORE ====
                                commit(`createMappingProfile`, {
                                    filename,
                                    value: response,
                                });
                                // ==== CACHE ====
                                await dispatch(
                                    `cache/profiles/cacheResource`,
                                    { resource: response },
                                    { root: true }
                                );
                                // ==== RETURN ====
                                return response;
                            } else {
                                // Save existing profile.
                                await dispatch(
                                    `cache/profiles/fetchIndex`,
                                    { ignoreCache: false },
                                    { root: true }
                                );
                                // ==== FETCH ====
                                const { profiles } = rootState.cache;
                                const existing = profiles.index.get(profile.id);
                                // ==== STORE ====
                                commit(`createMappingProfile`, {
                                    filename,
                                    value: existing,
                                });
                                // ==== CACHE ====
                                await dispatch(
                                    `cache/profiles/cacheResource`,
                                    { resource: existing },
                                    { root: true }
                                );
                                // ==== RETURN ====
                                return existing;
                            }
                        } finally {
                            commit(`disableRecordStatus`, {
                                record,
                                id: 'creating_mapping_profile',
                            });
                        }
                    },
                    /**
                     * Create mapping profiles for each record with a -1 id profile.
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     */
                    createRecordMappingProfiles: async (context) => {
                        const { state, dispatch } = context;
                        const records = state.records;
                        // ==== FILTER ====
                        const withSelectedProfile = [
                            ...records.values(),
                        ].filter((r) => r.isMappingProfileSelected);
                        // ==== CREATE ====
                        for (const record of withSelectedProfile) {
                            await dispatch(`createRecordMappingProfile`, {
                                filename: record.filename,
                            });
                        }
                    },
                };
                const apply = {
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {{ filename: String }} payload
                     */
                    applyRecordMappingProfile: async (context, payload) => {
                        // ts-ignore
                        const { state, rootState, commit, dispatch } = context;
                        // ts-ignore
                        const { accounts, cache } = rootState;
                        const { filename } = payload;
                        const records = state.records;
                        const record = records.get(filename);
                        await Assertion.expect(record).isNotNil();
                        await Assertion.expect(
                            record.mappingProfile.exists
                        ).isTruthy();
                        await Assertion.expect(
                            record.isApplyingMappingProfile
                        ).isFalsy();
                        await Assertion.expect(
                            record.isMappingProfileApplied
                        ).isFalsy();
                        try {
                            commit(`enableRecordStatus`, {
                                record,
                                id: 'applying_mapping_profile',
                            });
                            const profile = record.mappingProfile.value;
                            // Create request.
                            const request = {
                                id: record.batch.value.id,
                                profileId: record.mappingProfile.value.id,
                            };
                            const { account } = accounts;
                            const response =
                                await accountDatasets.updateAccountBatchById(
                                    account,
                                    request
                                );
                            commit(`applyMappingProfile`, {
                                filename,
                                profile,
                                batch: response.dataset,
                            });
                        } finally {
                            commit(`disableRecordStatus`, {
                                record,
                                id: 'applying_mapping_profile',
                            });
                        }
                    },
                    /**
                     * Create mapping profiles for each record with a -1 id profile.
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     */
                    applyRecordMappingProfiles: async (context) => {
                        const { state, dispatch } = context;
                        const records = state.records;
                        // ==== FILTER ====
                        const withCreatedProfile = [...records.values()].filter(
                            (r) => r.isMappingProfileCreated
                        );
                        // ==== APPLY ====
                        for (const record of withCreatedProfile) {
                            await dispatch(`applyRecordMappingProfile`, {
                                filename: record.filename,
                            });
                        }
                    },
                };
                const upload = {
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {*} config
                     */
                    // ts-ignore
                    uploadDatasets: async (context, config) => {
                        const { state, rootState, commit } = context;
                        const { accounts } = rootState;
                        const logger = ActionLogger.log(`[upload::datasets]`);

                        // ===== VERIFY =====
                        // - At least one record exists.
                        await Assertion.expect(state.records).isNotEmpty();

                        // ===== FILTER =====
                        // - wherePending: All records missing a batch.
                        const wherePending = collect(
                            [...state.records.values()].filter(
                                (r) => !r.isDatasetBatchUploaded
                            )
                        );

                        // ===== CONFIRM =====
                        if (wherePending.isNotEmpty()) {
                            // - At least one record needs to be uploaded.
                            logger.info(
                                `Uploading ${wherePending.count()} record(s).`
                            );
                            try {
                                // ===== VALIDATE =====
                                // - Every pending record has a selected file.
                                // - Every pending record has a selected location.
                                try {
                                    console.groupCollapsed(`[assert::records`);
                                    for (const record of wherePending) {
                                        await Assertion.expect(
                                            record.isFileSelected,
                                            'Has Selected File'
                                        ).isTruthy();
                                        await Assertion.expect(
                                            record.isLocationSelected,
                                            'Has Selected Location'
                                        ).isTruthy();
                                    }
                                } finally {
                                    console.groupEnd();
                                }

                                // ===== REQUEST =====
                                // - Create request with records in pending.
                                // - Enable the uploading status for pending records.
                                const request = wherePending
                                    .map((record) => {
                                        const file = record.file.value;
                                        const location = record.location.value;
                                        commit(`startRecordUpload`, {
                                            filename: file.name,
                                        });
                                        return { file, location };
                                    })
                                    .all();

                                // ===== UPLOAD =====
                                // - Send the upload request.
                                const { account } = accounts;
                                const collection = collect(
                                    await accountDatasets.createAccountBatches(
                                        account,
                                        request
                                    )
                                );
                                const responses = collection
                                    .filter((item) => item.status === 'created')
                                    .map((item) => ({
                                        filename: item.dataset?.filename,
                                        dataset: item.dataset,
                                        type: item.preview.type,
                                        contents: item.preview.data,
                                    }));

                                // ===== PROCESS =====
                                // - Handle response for each.
                                // - Stop upload once response is processed.
                                for (const response of responses) {
                                    // ===== CONFIRM RESPONSE =====
                                    // Get the matching pending record.
                                    const { filename } = response;
                                    const record = wherePending.first(
                                        (r) => r.filename === filename
                                    );
                                    await Assertion.expect(
                                        record,
                                        'Record Uploaded'
                                    ).isNotNil();

                                    // ===== PROCESS RESPONSE =====
                                    // Assign the file on success.
                                    // Assign the location on success.
                                    // Assign the dataset batch on success.
                                    const batch = response.dataset;
                                    const contents = response.contents;
                                    const file = record.file.value;
                                    const location = record.location.value;
                                    const type = response.type;
                                    commit(`storeUploadedRecord`, {
                                        filename,
                                        batch,
                                        contents,
                                        file,
                                        location,
                                        type,
                                    });
                                    // Stop the uploading process for the specified record.
                                    commit(`stopRecordUpload`, { filename });
                                }
                                // ===== NOTIFY =====
                                // - whereUploaded: All records now uploaded.
                                const whereUploaded = [
                                    ...state.records.values(),
                                ].filter((r) => r.isDatasetBatchUploaded);
                                return whereUploaded;
                            } catch (err) {
                                // ===== ERROR =====
                                logger.failure(err.message);
                                return null;
                            } finally {
                                // // ===== CLEANUP =====
                                // for (const [_, record] of state.records) {
                                //     if (
                                //         record.isStatusActive(
                                //             'uploading_dataset'
                                //         )
                                //     ) {
                                //         commit(`stopRecordUpload`, {
                                //             filename: record.filename,
                                //         });
                                //     }
                                // }
                            }
                        } else {
                            // - Nothing to upload.
                            logger.warning(`There are no records to upload.`);
                            return [];
                        }
                    },
                };
                const ingest = {
                    /**
                     * @param {import('vuex').ActionContext<UploadData, ECNBState>} context
                     * @param {*} config
                     */
                    // ts-ignore
                    ingestDatasets: async (context, config) => {
                        const { state, rootState, commit } = context;
                        const { accounts } = rootState;
                        const logger = ActionLogger.log(`[ingest::datasets]`);

                        // ===== VERIFY =====
                        // - At least one record exists.
                        await Assertion.expect(state.records).isNotEmpty();

                        // ===== FILTER =====
                        // - wherePending: All records not marked as ingested.
                        const wherePending = collect(
                            [...state.records.values()].filter(
                                (r) => !r.isDatasetBatchIngested
                            )
                        );

                        // ===== CONFIRM =====
                        if (wherePending.isNotEmpty()) {
                            // - At least one record needs to be ingested.
                            logger.info(
                                `Ingesting ${wherePending.count()} record(s).`
                            );
                            try {
                                // ===== VALIDATE =====
                                // - Every pending record has been uploaded.
                                // - Every pending record has a selected profile OR is a PEM file.
                                try {
                                    console.groupCollapsed(`[assert::records`);
                                    for (const record of wherePending) {
                                        await Assertion.expect(
                                            record.isDatasetBatchUploaded,
                                            'Has Uploaded Dataset'
                                        ).isTruthy();
                                        await Assertion.expect(
                                            record.isMappingProfileSelected ||
                                                record.isMarkedAsPEM,
                                            'Has Selected Mapping Profile'
                                        ).isTruthy();
                                    }
                                } finally {
                                    console.groupEnd();
                                }

                                // ===== REQUEST =====
                                // - Create request with records in pending.
                                // - Enable the ingesting status for pending records.
                                const requests = wherePending
                                    .map((record) => {
                                        // TODO =========================================================
                                        const batch = record.batch.value;
                                        commit(`startRecordIngest`, {
                                            filename: record.filename,
                                        });
                                        return { batch };
                                    })
                                    .all();
                                const { account } = accounts;
                                for (const request of requests) {
                                    const { batch } = request;
                                    try {
                                        // ===== INGEST =====
                                        // - Send the ingest request.
                                        const response =
                                            await accountDatasets.ingestAccountBatch(
                                                account,
                                                batch
                                            );
                                        // ===== PROCESS =====
                                        // - Handle response for each.
                                        // - Stop upload once response is processed.
                                        if (
                                            [200, 201, 'ingested'].includes(
                                                response.status
                                            )
                                        ) {
                                            // ===== CONFIRM RESPONSE =====
                                            // Get the matching pending record.
                                            const { filename } =
                                                response.dataset;
                                            const record = wherePending.first(
                                                (r) => r.filename === filename
                                            );
                                            await Assertion.expect(
                                                record,
                                                'Record Ingested'
                                            ).isNotNil();
                                            // ===== PROCESS RESPONSE =====
                                            // Assign the dataset batch on success.
                                            // Assign the profile on success.
                                            commit(`storeIngestedRecord`, {
                                                filename,
                                                batch: response.dataset,
                                                profile:
                                                    record.mappingProfile.value,
                                            });
                                            // Stop the ingesting process for the specified record.
                                            commit(`stopRecordIngest`, {
                                                filename,
                                            });
                                        } else {
                                            throw new Error(
                                                `Failed to ingest record.`
                                            );
                                        }
                                    } catch (error) {
                                        // ===== ERROR =====
                                        // - Send the ingest request.
                                        console.error(error);
                                    }
                                }
                                // ===== NOTIFY =====
                                // - whereIngested: All records now ingested.
                                const whereIngested = [
                                    ...state.records.values(),
                                ].filter((r) => r.isDatasetBatchIngested);
                                return whereIngested;
                            } catch (err) {
                                // ===== ERROR =====
                                logger.failure(err.message);
                                return null;
                            } finally {
                                // // ===== CLEANUP =====
                                // for (const [_, record] of state.records) {
                                //     if (
                                //         record.isStatusActive(
                                //             'ingesting_dataset'
                                //         )
                                //     ) {
                                //         commit(`stopRecordIngest`, {
                                //             filename: record.filename,
                                //         });
                                //     }
                                // }
                            }
                        } else {
                            // - Nothing to ingest.
                            logger.warning(`There are no records to ingest.`);
                            return [];
                        }
                    },
                };
                return {
                    ...lifecycle,
                    ...create,
                    ...drop,
                    ...select,
                    ...upload,
                    ...suggest,
                    ...apply,
                    ...ingest,
                };
            },
        };
    }
}
