// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Maybe } from 'true-myth/dist/maybe';
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Organization } from '@/models/v2/organizations';
import { Account } from '@/models/v2/accounts';
import { AccountPayload } from '@/payloads/v2/accounts';
import { User } from '@/models/v2/users';
import { UserPayload } from '@/payloads/v2/users';

// <!-- ROUTES -->

/** @type {Readonly<globalThis.User.Routes>} */
const URI = {
    index: () => `users`,
    show: (id) => `users/${id}`,
    create: () => `users`,
    update: (id) => `users/${id}`,
    delete: (id) => `users/${id}`,
    organizations: (id) => `users/${id}/organizations`,
    accounts: (id) => `users/${id}/accounts`,
    resetPassword: (id) => `users/${id}/password`,
    availableUsername: () => 'users/username-available',
    availableEmail: () => 'users/email-available',
};

// <!-- ENDPOINTS -->

/**
 * Get all available users.
 * @type {globalThis.User.API.FetchIndex}
 */
export const fetchUsers = async (config) => {
    try {
        /** @type {globalThis.User.Response.FetchedIndex} */
        const response = await axios().get(URI.index(), config);
        const empty = /** @type {globalThis.User.Payload[]} */ ([]);
        const data = Maybe.of(response.data?.data).unwrapOr(empty);
        const payloads = data.map((item) => new UserPayload(item));
        const models = payloads.map((payload) => new User(payload));
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get the specified user.
 * @type {globalThis.User.API.FetchResource}
 */
export const fetchUserById = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.FetchedResource} */
        const response = await axios().get(URI.show(request.id), config);
        const payload = new UserPayload(response.data?.user);
        const model = new User(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get all organizations the specified user is assigned to.
 * @type {globalThis.User.API.FetchOrganizations}
 */
export const fetchUserOrganizations = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.FetchedOrganizations} */
        const response = await axios().get(
            URI.organizations(request.id),
            config
        );
        const empty = /** @type {globalThis.Organization.Payload[]} */ ([]);
        const user = Maybe.of(response.data?.user).unwrapOr({});
        const data = Maybe.of(response.data?.organizations).unwrapOr(empty);
        const payloads = data.map(Organization.Payload.create);
        const models = payloads.map(Organization.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get all accounts the specified user is assigned to.
 * @type {globalThis.User.API.FetchAccounts}
 */
export const fetchUserAccounts = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.FetchedAccounts} */
        const response = await axios().get(URI.accounts(request.id), config);
        const empty = /** @type {globalThis.Account.Payload[]} */ ([]);
        const user = Maybe.of(response.data?.user).unwrapOr({});
        const data = Maybe.of(response.data?.accounts).unwrapOr(empty);
        const payloads = data.map((item) => new AccountPayload(item));
        const models = payloads.map((payload) => new Account(payload));
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Create a new user.
 * @type {globalThis.User.API.CreateResource}
 */
export const createUser = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.CreatedResource} */
        const response = await axios().post(URI.create(), request, config);
        const payload = new UserPayload(response.data.user);
        const model = new User(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Update the specified user.
 * @type {globalThis.User.API.UpdateResource}
 */
export const updateUserById = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.UpdatedResource} */
        const response = await axios().put(
            URI.update(request.id),
            request,
            config
        );
        const payload = new UserPayload(response.data.user);
        const model = new User(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Delete the specified user.
 * @type {globalThis.User.API.DeleteResource}
 */
export const deleteUserById = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.DeletedResource} */
        const response = await axios().delete(URI.delete(request.id), config);
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Send a password reset email for the specified user.
 * @type {globalThis.User.API.ResetPassword}
 */
export const resetPassword = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.ResetPassword} */
        const response = await axios().patch(
            URI.resetPassword(request.id),
            request,
            config
        );
        const result = Result.ok(response.status === 200);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Check if the specified email is available for a new user.
 * @type {globalThis.User.API.AvailableUserName}
 */
export const availableUserName = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.AvailableUserName} */
        const response = await axios().post(
            URI.availableUsername(),
            request,
            config
        );
        // When the username is not taken, we receive a 204 no content status.
        if (response.status === 204) {
            return Result.ok(true);
        }
        // For any other instance, this will throw an error.
        throw new Error('Bad request.');
    } catch (e) {
        // When the username is already taken, return a `Result.ok(false)`.
        if (
            e instanceof AxiosError &&
            e.response?.status === 422 &&
            e.response?.data?.message === 'The username has already been taken.'
        ) {
            return Result.ok(false);
        }
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Check if the specified email is available for a new user.
 * @type {globalThis.User.API.AvailableUserEmail}
 */
export const availableUserEmail = async (request, config) => {
    try {
        /** @type {globalThis.User.Response.AvailableUserEmail} */
        const response = await axios().post(
            URI.availableEmail(),
            request,
            config
        );
        // When the email is not taken, we receive a 204 no content status.
        if (response.status === 204) {
            return Result.ok(true);
        }
        // For any other instance, this will throw an error.
        throw new Error('Bad request.');
    } catch (e) {
        // When the email is already taken, return a `Result.ok(false)`.
        if (
            e instanceof AxiosError &&
            e.response?.status === 422 &&
            e.response?.data?.message === 'The email has already been taken.'
        ) {
            return Result.ok(false);
        }
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<globalThis.User.API>} */
export default {
    fetchUsers,
    fetchUserById,
    createUser,
    updateUserById,
    deleteUserById,
    fetchUserOrganizations,
    fetchUserAccounts,
    resetPassword,
    availableUserEmail,
    availableUserName,
};
